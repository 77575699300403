import { styles } from './styles';
import { Box, FormControl, MenuItem, Select, Typography, styled } from '@mui/material';
import CustomerSatisfactionSkeleton from './satisfactionSkeleton';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import USER_IMG from 'assets/images/user-image.png';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from 'utils/modals';
import { getManageDisputeDetails, updateDisputeStatus } from 'modules/customerSatisfaction/action';
import CALENDER_IMG from 'assets/images/calendar.png';
import moment from 'moment';
import { DISPUTE_STATUS } from 'utils/enum';
import ContainedButton from 'components/button/contained-button';
import ROUTE_NAMES from 'routes/routeNames';
import {
    UPDATE_CLIENT_REDUCER,
    UPDATE_FLAGGED_REDUCER,
    UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
    UPDATE_PHOTOGRAPHERS_REDUCER,
} from 'store/actionTypes';
import { blockUnblock } from 'modules/clientManagement/action';
import { blockUnblock as blockUnblockVendor } from 'modules/photographersManagement/action';
import { getFirebaseMessageDetails } from 'utils/constants';
import SHOW_TOAST from 'utils/showToast';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '12px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '18px 32px 5px 32px',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));
const CardContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
}));

const HeadingContainer = styled('div')(() => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid black',
    paddingLeft: '32px',
}));

const DetailContainer = styled('div')(() => ({
    width: '100%',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '32px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
}));

const MangeDisputeDetail = () => {
    const {
        bookingDetails,
        startTime,
        endTime,
        vendorDetails,
        clientDetails,
        paid,
        paidOn,
        paymentMethod,
        createdAt,
        grievanceRef,
        grievances,
        message,
        loader,
        bookingRef,
        bookingId,
        givenBy,
        refundInitiated,
        PaymentDetails,
        requestStatus,
        clientStatus,
        vendorStatus,
    } = useSelector((state: ReducersModal) => state.ManageDisputeDetailsReducer);
    const { messages } = useSelector((state: ReducersModal) => state.FlaggedAccountReducer);
    const dispatch: any = useDispatch();
    const navigate: any = useNavigate();
    const [imgError, setImgError] = useState('valid');
    const [chatMessage, setChatMessage] = useState<any>([]);
    const grievanceId = new URLSearchParams(window.location.search).get('id');
    const senderType: any = new URLSearchParams(window.location.search).get('senderType') || 0;

    useLayoutEffect(() => {
        dispatch({
            type: UPDATE_FLAGGED_REDUCER,
            payload: {
                messages: [],
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getFirebaseMessageDetails(bookingId, parseInt(senderType), dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getFirebaseMessageDetails(bookingId, parseInt(senderType), dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId]);

    useEffect(() => {
        setChatMessage(messages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(messages)]);

    useEffect(() => {
        dispatch(getManageDisputeDetails(grievanceId, 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grievanceId]);

    const handleChange = (e: any) => {
        const status = e.target.value;
        dispatchDisputeValue({ requestStatus: status });
        const dataToSend = {
            grievanceId,
            status,
        };
        dispatch(updateDisputeStatus(dataToSend, () => {}));
    };

    const dispatchDisputeValue = (payload: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
            payload,
        });
    };

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_REDUCER,
            payload,
        });
    };

    const dispatchVendorValue = (payload: any) => {
        dispatch({
            type: UPDATE_PHOTOGRAPHERS_REDUCER,
            payload,
        });
    };

    const onBlockClick = () => {
        let toBlockId = givenBy === 1 ? clientDetails?._id : vendorDetails?._id;
        let newStatus = givenBy === 1 ? (clientStatus === 3 ? 2 : 3) : vendorStatus === 3 ? 2 : 3;
        let endPoint =
            givenBy === 1
                ? `/status?clientId=${toBlockId}&status=${newStatus}`
                : `/status?vendorId=${toBlockId}&status=${newStatus}`;
        if (givenBy === 1) {
            dispatch(
                blockUnblock(endPoint, (res: any) => {
                    if (res.isSuccess) {
                        dispatchValue({ status: null });
                        dispatchDisputeValue({ clientStatus: clientStatus === 3 ? 2 : 3 });
                    }
                }),
            );
        } else {
            dispatch(
                blockUnblockVendor(endPoint, (res: any) => {
                    if (res.isSuccess) {
                        dispatchVendorValue({ status: null });
                        dispatchDisputeValue({ vendorStatus: vendorStatus === 3 ? 2 : 3 });
                    }
                }),
            );
        }
    };
    const renderHeading = (label: string, status?: number) => {
        return (
            <Typography
                variant='h3'
                component={'h3'}
                sx={{ minWidth: status === 1 ? '90px' : '200px', fontSize: '18px' }}>
                {label || 'N/A'}
            </Typography>
        );
    };

    const renderValue = (value: any) => {
        return (
            <Typography variant='h4' component={'h4'} sx={styles.value_text}>
                {value}
            </Typography>
        );
    };

    const renderLocation = (val: any) => {
        if (val?.street || val?.city || val?.postalCode || val?.country) {
            return (
                <Typography
                    variant='h4'
                    component={'h4'}
                    sx={{ ...styles.value_text, maxWidth: '450px' }}>
                    {`${val?.street}${val?.street && val?.city && ','}  ${val?.city} ${
                        val?.country && val?.city && ','
                    } ${val?.country}${val?.postalCode && val?.country && ','}  ${val?.postalCode}`}
                </Typography>
            );
        }
        return (
            <Typography
                variant='h4'
                component={'h4'}
                sx={{ ...styles.value_text, maxWidth: '450px' }}>
                {'N/A'}
            </Typography>
        );
    };

    const renderVerifiedStatus = () => {
        return (
            <FormControl
                sx={{
                    width: '130px',
                    height: '38px',
                    boxShadow: 'none',
                    fontSize: '30px',
                }}>
                <Select
                    displayEmpty
                    onChange={(e: any) => handleChange(e)}
                    sx={styles.selectDropdown}
                    value={requestStatus}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {Object.values(DISPUTE_STATUS).map((status: any, index: number) => {
                        return (
                            <MenuItem
                                disabled={status === Object.keys(DISPUTE_STATUS)[index]}
                                value={Object.keys(DISPUTE_STATUS)[index]}
                                key={index}>
                                {status}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    const renderCalenderImg = () => {
        return (
            <Box sx={{ height: '20px', width: '20px', marginRight: '10px' }}>
                <img
                    src={CALENDER_IMG}
                    alt='calender-img'
                    style={{ height: '18px', width: '18px' }}></img>
            </Box>
        );
    };

    const renderProfileView = (type: number) => {
        const user_img = type === 1 ? vendorDetails?.profilePic : clientDetails?.profilePic;
        const user_name = type === 1 ? vendorDetails?.fullName : clientDetails?.fullName;
        // To check weather image is valid or not through image event handler
        const handleError = (e: any) => {
            if (e.type === 'error') {
                setImgError('error');
            }
        };
        const checkSrc = () => {
            if (!user_img || imgError === 'error') {
                return USER_IMG;
            } else {
                return user_img;
            }
        };
        return (
            <Box sx={styles.clientProfilePicCont}>
                <img
                    onError={(e: any) => handleError(e)}
                    style={styles.img}
                    src={checkSrc()}
                    alt='vendor-img'></img>
                {user_name ? renderHeading(user_name) : 'N/A'}
            </Box>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <React.Fragment>
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Payment Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer>
                            <LineContainer>
                                {renderHeading('Paid')}
                                {renderValue(`${'$'}${paid}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Paid on')}
                                {renderCalenderImg()}
                                {renderValue(
                                    paidOn ? moment(paidOn).format('MM/DD/YYYY, hh:mm A') : 'N/A',
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Payment Method')}
                                {renderValue(paymentMethod)}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            </React.Fragment>
        );
    };
    /**
     *
     * @returns Booking details section against specific rating
     */
    const renderbookingDetails = () => {
        return (
            <React.Fragment>
                <TableContainer sx={{ marginTop: '10px' }}>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Booking Details'}</Typography>
                        </HeadingContainer>
                        <br></br>
                        <DetailContainer>
                            <LineContainer>
                                {renderHeading('Booking Id')}
                                {renderValue(bookingRef || 'N/A')}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Category')}
                                {renderValue(bookingDetails?.category?.name || 'N/A')}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Booking Location')}
                                {renderLocation(bookingDetails?.shootLocation)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Pricing')}
                                {renderValue(`$${bookingDetails?.amount || 0}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Date  and Time')}
                                {renderCalenderImg()}
                                {renderValue(
                                    startTime
                                        ? `${moment(startTime).format(
                                              'MM/DD/YYYY, hh:mm A',
                                          )} ${' - '} ${moment(endTime).format('hh:mm A')}`
                                        : 'N/A',
                                )}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            </React.Fragment>
        );
    };

    const renderBasicDetail = () => {
        return (
            <React.Fragment>
                <Box sx={styles.headContainer}>
                    <ContainedButton
                        externalStyles={{ marginRight: '20px' }}
                        onClick={() => {
                            if (chatMessage.length === 0) {
                                SHOW_TOAST('No Messages to show', 'info');
                            } else {
                                navigate(
                                    `${ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION_CHAT}?id=${bookingId}&senderType=${senderType}`,
                                );
                            }
                        }}
                        title={'View Chat'}
                    />
                    <ContainedButton
                        externalStyles={{ marginRight: '20px' }}
                        title={
                            givenBy === 1
                                ? clientStatus === 3
                                    ? 'Unblock'
                                    : 'block'
                                : vendorStatus === 3
                                ? 'Unblock'
                                : 'block'
                        }
                        onClick={() => onBlockClick()}
                    />
                    {refundInitiated && (
                        <ContainedButton
                            onClick={() => {
                                window.open(
                                    `https://dashboard.stripe.com/payments/${PaymentDetails?.payment_intent}`,
                                    '_blank',
                                    'noreferrer',
                                );
                            }}
                            title={'Initiate Refund'}
                        />
                    )}
                </Box>
                <TableContainer sx={{ display: 'block' }}>
                    <ItemContainer>
                        <LineContainer
                            sx={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
                            <LineContainer>
                                {renderHeading('Reported Time')}
                                {renderValue(moment(createdAt).format('MM/DD/YYYY, hh:mm A'))}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Report ID', 1)}
                                {renderValue(grievanceRef)}
                            </LineContainer>
                            {renderVerifiedStatus()}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Reported Reason')}
                            {renderValue(grievances)}
                        </LineContainer>
                        {grievances === 'Other' && (
                            <LineContainer>
                                <Typography
                                    variant='h4'
                                    component={'h4'}
                                    sx={[styles.value_text, { maxWidth: 'unset' }]}>
                                    {message || 'N/A'}
                                </Typography>
                            </LineContainer>
                        )}

                        <Box sx={styles.profile_cont}>
                            <LineContainer>
                                {renderHeading('Reported By', 1)}
                                {renderProfileView(2)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Reported To', 1)}
                                {renderProfileView(1)}
                            </LineContainer>
                        </Box>
                    </ItemContainer>
                </TableContainer>
                {renderbookingDetails()}
                {renderPaymentDetails()}
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loader) {
            return <CustomerSatisfactionSkeleton />;
        }
        return renderBasicDetail();
    };

    return (
        <Box sx={styles.paper}>
            <Box sx={styles.container}>{renderView()}</Box>
        </Box>
    );
};

export default MangeDisputeDetail;
