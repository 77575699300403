import { styles } from './styles';
import { Box, Skeleton, Typography, styled } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import USER_IMG from 'assets/images/user-image.png';
import { useDispatch, useSelector } from 'react-redux';
import { getEarnAdTransactionDetail } from './action';
import { ReducersModal } from 'utils/modals';
import { status, statusCss } from 'utils/enum';
import CALENDER_IMG from 'assets/images/calendar.png';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '24px 32px 18px 30px',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));
// const CardContainer = styled('div')(() => ({
//     width: '100%',
//     marginBottom: '24px',
//     borderRadius: '10px',
//     border: '1px solid #000000;',
//     background: 'white',
//     boxSizing: 'border-box',
//     WebkitBoxSizing: 'border-box',
//     MozBoxSizing: 'border-box',
//     position: 'relative',
// }));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
}));

// const HeadingContainer = styled('div')(() => ({
//     width: '100%',
//     height: '50px',
//     display: 'flex',
//     alignItems: 'center',
//     borderBottom: '1px solid black',
//     paddingLeft: '20px',
// }));

// const DetailContainer = styled('div')(() => ({
//     width: '100%',
//     height: '150px',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     paddingLeft: '20px',
// }));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '17px',
}));

const EarningsTransactionsDetail = () => {
    const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();

    const { bookingDetails, transactionData, vendorDetails, clientDetails, startTime, endTime } =
        useSelector((state: ReducersModal) => state.EarnAndTransactionDetailReducer);
    const { status: bookingStatus } = transactionData;
    const bookingId = new URLSearchParams(window.location.search).get('id') || '';

    useLayoutEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (bookingId && bookingId.length > 0) {
            dispatch(
                getEarnAdTransactionDetail(bookingId, () => {
                    setLoading(false);
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const renderHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '323px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderCalenderImg = () => {
        return (
            <Box sx={styles.calendarImgCont}>
                <img
                    src={CALENDER_IMG}
                    alt='calender-img'
                    style={{ height: '18px', width: '18px' }}></img>
            </Box>
        );
    };

    const EarningSkeleton = () => {
        return (
            <React.Fragment>
                <Skeleton
                    height={'310px'}
                    width={'100%'}
                    variant='rectangular'
                    sx={{ borderRadius: '10px' }}
                />
            </React.Fragment>
        );
    };

    const renderProfileView = (type: number) => {
        const user_img = vendorDetails?.profilePic;
        const user_name = type === 1 ? vendorDetails?.fullName : clientDetails?.fullName;
        return (
            <Box sx={styles.clientProfilePicCont}>
                <img style={styles.img} src={user_img || USER_IMG} alt='vendor-img'></img>
                {user_name ? renderHeading(user_name) : 'N/A'}
            </Box>
        );
    };

    const renderAddons = () => {
        if (bookingDetails) {
            return (
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {bookingDetails?.addOnServices?.length > 0 ? (
                            bookingDetails?.addOnServices?.map((item: any, index: number) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '20px',
                                            justifyContent: 'flex-start',
                                            marginBottom:
                                                index !== bookingDetails?.addOnServices?.length - 1
                                                    ? '5px'
                                                    : 'unset',
                                        }}
                                        key={item?.name}>
                                        <Typography
                                            variant='h4'
                                            component={'h4'}
                                            sx={[styles.addOnText, { minWidth: '45px' }]}>
                                            {`${'$'}${item?.rate}`}
                                        </Typography>
                                        <Typography
                                            variant='h4'
                                            component={'h4'}
                                            sx={styles.addOnText}>
                                            {item?.name}
                                        </Typography>
                                    </Box>
                                );
                            })
                        ) : (
                            <Typography variant='h4' component={'h4'} sx={styles.addOnText}>
                                N/A
                            </Typography>
                        )}
                    </Box>
                </React.Fragment>
            );
        }
        return null;
    };

    const renderValue = (value: string | number) => {
        return (
            <Typography variant='h4' component={'h4'} sx={styles.addOnText}>
                {value}
            </Typography>
        );
    };

    const renderDateRange = (startTime: string, endTime: string) => {
        return (
            <Box sx={styles.flexContainer}>
                {renderCalenderImg()}
                <Typography variant='h4' component={'h4'} sx={styles.addOnText}>
                    {startTime}
                    {' - '}
                    {endTime}
                </Typography>
            </Box>
        );
    };
    // const renderPaymentDetails = () => {
    //     return (
    //         <TableContainer>
    //             <CardContainer>
    //                 <HeadingContainer>
    //                     <Typography variant='h4'>{'Payment Details'}</Typography>
    //                 </HeadingContainer>
    //                 <DetailContainer>
    //                     <LineContainer>
    //                         {renderHeading('Paid')}
    //                         {renderValue('$175')}
    //                     </LineContainer>
    //                     <LineContainer>
    //                         {renderHeading('Paid on')}
    //                         {renderValue('11/6/2021,    07:25')}
    //                     </LineContainer>
    //                     <LineContainer>
    //                         {renderHeading('Payment Method')}
    //                         {renderValue('Credit Card')}
    //                     </LineContainer>
    //                 </DetailContainer>
    //             </CardContainer>
    //         </TableContainer>
    //     );
    // };

    const renderLocation = (location: any) => {
        return (
            <LineContainer>
                {renderHeading('Booking Location')}
                <Typography sx={styles.addOnText} variant='h4' component={'h4'}>
                    {`${location?.street}${location?.street && location?.city && ','}  ${
                        location?.city
                    } ${location?.country && location?.city && ','} ${location?.country}${
                        location?.postalCode && location?.country && ','
                    }  ${location?.postalCode}`}
                </Typography>
            </LineContainer>
        );
    };

    const renderUpperRightSection = () => {
        return (
            <Box sx={styles.button_stack}>
                <Box sx={[styles.approvedRejectedBtnCon, { background: statusCss[bookingStatus] }]}>
                    <Typography sx={styles.name}>{status[bookingStatus]}</Typography>
                </Box>
            </Box>
        );
    };

    const renderTransaction = () => {
        return (
            <React.Fragment>
                <TableContainer sx={{ display: 'block' }}>
                    <ItemContainer>
                        <LineContainer>
                            {renderHeading('Transaction ID')}
                            {renderValue(transactionData?.transactionRef)}
                            {renderUpperRightSection()}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Client Name')}
                            {renderProfileView(2)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Photographer Name')}
                            {renderProfileView(1)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Category')}
                            {renderValue(bookingDetails?.category?.name)}
                        </LineContainer>
                        {renderLocation(bookingDetails?.shootLocation)}
                        <LineContainer>
                            {renderHeading('Pricing')}
                            {renderValue(
                                `${'$'}${
                                    transactionData?.orderCalculations?.totalBaseShootCharges || '0'
                                }`,
                            )}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Date and Time')}
                            {renderDateRange(
                                moment(startTime).format('MMM DD, yyyy, hh:mm A'),
                                moment(endTime).format('hh:mm A'),
                            )}
                        </LineContainer>
                        <LineContainer sx={{ alignItems: 'flex-start' }}>
                            {renderHeading('Add ons')}
                            {renderAddons()}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Total')}
                            {renderValue(`${'$'}${transactionData?.amount}`)}
                        </LineContainer>
                    </ItemContainer>
                </TableContainer>
                {/* {renderPaymentDetails()} */}
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loading) {
            return <EarningSkeleton />;
        }
        return renderTransaction();
    };

    return renderView();
};

export default EarningsTransactionsDetail;
