import React, { useEffect, useLayoutEffect, useState } from 'react';
import Message from './Message';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import moment from 'moment';
import { getFirebaseMessageDetails } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from 'utils/modals';
import { UPDATE_FLAGGED_REDUCER } from 'store/actionTypes';

const ChatBox = () => {
    const { messages, clientDetails, vendorDetails } = useSelector(
        (state: ReducersModal) => state.FlaggedAccountReducer,
    );
    const [chatMessages, setChatMessages] = useState<[]>([]);
    const bookingId: any = new URLSearchParams(window.location.search).get('id');
    const senderType: any = new URLSearchParams(window.location.search).get('senderType') || 0;
    const dispatch: any = useDispatch();
    useLayoutEffect(() => {
        setChatMessages([]);
        dispatch({
            type: UPDATE_FLAGGED_REDUCER,
            payload: {
                messages: [],
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getFirebaseMessageDetails(bookingId, parseInt(senderType), dispatch);

        const sortDataByDate = Object.values(messages)?.reduce((obj: any, item: any) => {
            if (obj[moment(item.createdAt).format('MM/DD/YYYY')]) {
                obj[moment(item.createdAt).format('MM/DD/YYYY')].push(item);

                return obj;
            }

            obj[moment(item.createdAt).format('MM/DD/YYYY')] = [{ ...item }];
            return obj;
        }, {});
        setChatMessages(sortDataByDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(messages)]);

    return (
        <main className='chat-box'>
            <div className='messages-wrapper'>
                <Box sx={styles.paper}>
                    {chatMessages ? (
                        Object.values(chatMessages)?.map((thread: any, idx: number) => {
                            return (
                                <React.Fragment key={idx}>
                                    <Typography variant='h4' sx={styles.dateStyle}>
                                        {Object.keys(chatMessages)[idx]}
                                    </Typography>

                                    {thread?.map((message: any, index: number) => {
                                        return (
                                            <Message
                                                key={index}
                                                isClientActive={message?.senderType === 1}
                                                isPhotographerActive={message?.senderType === 2}
                                                sameAsPreviousSender={
                                                    Object.values(chatMessages)[idx][index] &&
                                                    Object.values(chatMessages)[idx][index - 1]
                                                        ? Object.values(chatMessages)[idx][index][
                                                              'senderType'
                                                          ] ===
                                                          Object.values(chatMessages)[idx][
                                                              index - 1
                                                          ]['senderType']
                                                        : false
                                                }
                                                senderType={senderType}
                                                message={message.messageContent}
                                                messageTime={message.createdAt}
                                                clientInfo={
                                                    message?.senderType === 1 ? clientDetails : {}
                                                }
                                                photographerInfo={
                                                    message?.senderType === 2 ? vendorDetails : {}
                                                }
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <Typography variant='h4' sx={styles.dateStyle}>
                            No Chat Intitiated yet
                        </Typography>
                    )}
                </Box>
            </div>
        </main>
    );
};
export default ChatBox;
