export const styles = {
    container: {
        padding: '0px 20px 0px 20px',
        // overflow: 'scroll',
    },
    modal_container: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minHeight: 300,
        height: 'fit-content',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        boxShadow: 24,
        borderRadius: '12px',
    },
    modal_card: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minHeight: 300,
        height: 'fit-content',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        boxShadow: 24,
        borderRadius: '12px',
    },
    upper: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        marginBottom: '10px',
        paddingLeft: '5px',
        flexDirection: 'row',
    },
    upper_right: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    img_container: {
        height: 'auto',
        width: '190px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '40px',
        boxSizing: 'borderbox',
        flexDirection: 'column',
    },
    profileImgCon: {
        width: '190px',
        height: '190px',
        borderRadius: '50%',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        width: '100%',
        height: '100%',
    },
    counts_container: {
        height: '120px',
        width: '145px',
        border: '2px dotted lightGray',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '',
    },
    middle: {
        width: '40%',
        height: '50px',
        marginBottom: '10px',
        borderRadius: '12px',
    },
    lower: {
        width: '100%',
        height: 'fix-content',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        gap: '2px',
    },
    data: {
        display: 'flex',
        marginTop: '10px',
        alignItems: 'center',
    },
    item_name: {
        width: '300px',
    },
    name: {
        fontWeight: 500,
        fontSize: '0.80rem',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: 'grey',
    },
    email: {
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: '#979797',
        display: 'flex',
    },
    table: {
        width: '50%',
        height: 'fit-content',
        margin: '10px 0px 15px 0px ',
    },
    table_head: {
        width: '100%',
        height: '35px',
        display: 'flex',
        alignItems: 'space-between',
        flexDirection: 'row',
    },
    table_row: {
        width: '100%',
        height: '35px',
        display: 'flex',
        alignItems: 'space-between',
    },
    table_cell: {
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid black',
        justifyContent: 'center',
        fontSize: '1.2rem',
    },
    table_cell_left: {
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid black',
        justifyContent: 'center',
    },
    table_cell_right: {
        width: '25%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid black',
        justifyContent: 'center',
    },
    button_stack: {
        height: '40px',
        alignSelf: 'end',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '48px',
        justifyContent: 'space-between',
        gap: '10px',
    },
    counts_stack: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    selectDropdown: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        '& > div': {
            background: 'none',
        },
        '& fieldset': {
            borderRadius: '4px',
            height: '40px',
        },
    },
    heading_ac: {
        color: '#673ab7',
    },
    button: {
        padding: '70px 20px 20px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
    },

    inputBox: {
        padding: '20px',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        height: '150px',
        width: '150px',
        border: '1px dotted lightGray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '6px 0px 6px 0px',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
    },
    headingBar: {
        width: '100%',
        height: '50px',
        display: 'flex',
        // justifyContent: 'center',
        borderBottom: '1px solid lightGray',
        alignItems: 'center',
        padding: '0px 10px 0px 20px',
        boxSizing: 'border-box',
    },
    logs_container: {
        width: '100%',
        height: 'fit-content',
        borderRadius: '4px',
        border: '1px solid black',
        margin: '10px 0px 15px 0px',
    },
    logs_header: {
        width: '100%',
        height: '30px',
        background: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logs_cont: {
        width: '98%',
        height: '60px',
        border: '1px solid lightGray',
        borderRadius: '4px',
        margin: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    govtId: {
        width: '60px',
        height: '70px',
    },
    availability: {
        backgroundColor: 'black',
        color: '#fff',
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid #fff',
        justifyContent: 'center',
    },
    approvedRejectedBtnCon: {
        height: '40px',
        width: '126px',
        display: 'flex',
        borderRadius: '7px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    user_img: {
        height: '40px',
        width: '40px',
        marginRight: '10px',
    },
};
