import React, { useEffect, useLayoutEffect, useState } from 'react';
import Loader from 'components/loader';
import { styles } from './styles';
import { Box, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_EARN_AND_TRANSACTION_REDUCER } from 'store/actionTypes';
import { ReducersModal } from 'utils/modals';
import SearchSection from 'components/search';
import EarningsTransactionsTable from 'components/tables/earningTransactionManagement';
import RenderBarChart from 'modules/earningTransactionManagement/graphRepresentation/graph';
import { getEarnAdTransactionListing, getTransactionReportData } from './action';
import ContainedButton from 'components/button/contained-button';
// import EarnAndTransactionFilter from 'components/filter/earnAndTransFilter';
// import { getCityZipCodes } from 'modules/clientManagement/action';

const EarningAndTransactionManagement = () => {
    const { search, loader, result, page, cityFilter, zipCodeFilter, reports } = useSelector(
        (state: ReducersModal) => state.EarnAndTransactionReducer,
    );
    const [filteredEarnAndTransReports, setFilteredEarnAndTransReportsData] = useState<[]>([]);
    const [earnAndTransState, setEarnAndTransState] = useState(4);
    // const [cityList, setCityList] = useState<CityZipCodeItemModal[]>([]);
    // const [zipCodeList, setZipCodeList] = useState<CityZipCodeItemModal[]>([]);

    const dispatch: any = useDispatch();
    useEffect(() => {
        dispatch(getEarnAdTransactionListing());
    }, [dispatch, search, cityFilter, zipCodeFilter]);

    useEffect(() => {
        dispatch(getTransactionReportData(earnAndTransState));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let uniqueData: any = [];
        if (reports.length > 0 && earnAndTransState !== 1) {
            uniqueData = reports.splice(1, reports.length);
        } else {
            uniqueData = reports;
        }
        setFilteredEarnAndTransReportsData(uniqueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports]);

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload,
        });
    };
    useLayoutEffect(() => {
        dispatchValue({
            reports: [],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDurationFilter = (e: any, filterKey: any) => {
        dispatch(getTransactionReportData(parseInt(e.target.value)));
        setEarnAndTransState(parseInt(e.target.value));
    };

    // useEffect(() => {
    //     //getting city and zipcode data
    //     dispatch(
    //         getCityZipCodes(1, 1, ({ isSuccess, data: cityData }: APIResponseModal) => {
    //             if (isSuccess) {
    //                 setCityList(
    //                     [...cityData.result].filter((a: any) => a?.city && a?.city?.length > 0),
    //                 );
    //             }
    //         }),
    //     );
    //     dispatch(
    //         getCityZipCodes(2, 1, ({ isSuccess, data: zipCodeData }: APIResponseModal) => {
    //             if (isSuccess) {
    //                 setZipCodeList(
    //                     [...zipCodeData.result].filter(
    //                         (a: any) => a?.postalCode && a?.postalCode?.length > 0,
    //                     ),
    //                 );
    //             }
    //         }),
    //     );
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleSearch = (searchVal: string) => {
        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload: { search: searchVal, page: 1 },
        });
    };

    // const onApplyFilters = ({ selectedCity, selectedCode }: any) => {
    //     dispatch({
    //         type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
    //         payload: {
    //             cityFilter: selectedCity,
    //             zipCodeFilter: selectedCode,
    //         },
    //     });
    // };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <Box sx={styles.searchAndFilter}>
                    <Skeleton
                        height={48}
                        width={300}
                        variant='rectangular'
                        sx={{ borderRadius: '12px' }}
                    />
                </Box>
                <Skeleton
                    height={500}
                    width={'100%'}
                    variant='rectangular'
                    sx={{ borderRadius: '8px', marginTop: '16px' }}
                />
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (
            loader &&
            result.length === 0 &&
            page === 1 &&
            search.length === 0 &&
            reports.length === 0
        ) {
            return renderLoading();
        }
        return (
            <React.Fragment>
                <RenderBarChart
                    filteredData={filteredEarnAndTransReports}
                    durationState={earnAndTransState}
                    onDurationFilter={onDurationFilter}
                />
                <br></br>
                <Box sx={styles.searchAndFilter}>
                    <SearchSection
                        value={search}
                        isDisabled={loader}
                        onChange={handleSearch}
                        placeholder={'Search by Name, Transaction Id'}
                    />
                    <Box sx={styles.flexContainer}>
                        <ContainedButton
                            title='Export to CSV'
                            style={styles.csv_btn}></ContainedButton>
                        {/* <EarnAndTransactionFilter
                            cityList={cityList}
                            cityValue={cityFilter}
                            codeValue={zipCodeFilter}
                            zipCodeList={zipCodeList}
                            applyFilters={onApplyFilters}
                        /> */}
                    </Box>
                </Box>
                <EarningsTransactionsTable />
            </React.Fragment>
        );
    };

    return (
        <Box>
            {renderView()}
            {loader && (result.length > 0 || (result.length === 0 && search.length > 0)) && (
                <Loader />
            )}
        </Box>
    );
};

export default EarningAndTransactionManagement;
