import moment from 'moment';
import React from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { DataFormater } from 'utils/constants';

interface ChartPropsInterface {
    result: any;
    activeTab: number;
    xLabel: string;
    yLabel: string;
    xDataKey: string;
    yDataKey: string;
    durationState: number;
}

const Chart = (props: ChartPropsInterface) => {
    const { result, activeTab, xLabel, yLabel, xDataKey, yDataKey, durationState } = props;

    const customizedGroupTick = (props: any) => {
        const { x, y, payload } = props;
        if (payload?.value) {
            return (
                <g>
                    {durationState === 1 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {moment(payload?.value).format('MM/DD/YYYY')}
                        </text>
                    )}

                    {durationState === 2 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {payload?.value}
                        </text>
                    )}

                    {durationState === 3 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {moment(payload?.value).format('MM/DD/YYYY')}
                        </text>
                    )}

                    {durationState === 4 && (
                        <text x={x - 17} y={y + 40} fill='#262626'>
                            {payload?.value}
                        </text>
                    )}
                </g>
            );
        }
        return <text></text>;
    };

    const customizedMonthTick = (props: any) => {
        const { x, y, payload } = props;

        if (payload?.value) {
            return (
                <g>
                    <text x={x - 12} y={y - 10}>
                        {payload?.value?.slice(0, 3)}
                    </text>
                </g>
            );
        }
        return <text></text>;
    };

    const defaultState = [
        {
            year: 0,
            month: '',
            count: 0,
        },
    ];

    return (
        <React.Fragment>
            <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                    width={500}
                    height={300}
                    barSize={55}
                    data={result && result.length > 0 ? result : defaultState}
                    margin={{
                        top: 35,
                        right: activeTab === 4 || activeTab === 3 ? 10 : 30,
                        left: activeTab === 4 || activeTab === 3 ? 5 : 20,
                        bottom: activeTab === 4 || activeTab === 3 ? 10 : 43,
                    }}>
                    <CartesianGrid strokeDasharray='3 3' vertical={false} />
                    {durationState === 4 && (
                        <XAxis
                            dataKey='month'
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            tick={customizedMonthTick}
                            xAxisId='quarter'
                        />
                    )}
                    {activeTab === 3 || activeTab === 4 ? (
                        <XAxis allowDataOverflow={true} dataKey={xDataKey}>
                            <Label position='center' value={xLabel} dy={36} />
                        </XAxis>
                    ) : (
                        <XAxis dataKey={xDataKey} tick={customizedGroupTick}>
                            <Label
                                position='center'
                                value={xLabel}
                                dy={durationState === 4 ? 55 : 36}
                            />
                        </XAxis>
                    )}

                    <YAxis dataKey={yDataKey} tickFormatter={DataFormater}>
                        <Label
                            style={{
                                textAnchor: 'middle',
                            }}
                            position='insideLeft'
                            offset={5}
                            angle={270}
                            value={yLabel}
                        />
                    </YAxis>
                    {result?.length > 0 && (
                        <Tooltip
                            labelFormatter={(value) => {
                                if (durationState === 3 || durationState === 1) {
                                    return `${moment(value).format('MM/DD/YYYY')}`;
                                } else {
                                    return `${value}`;
                                }
                            }}
                            formatter={(value) => `${parseInt(value.toString()).toLocaleString()}`}
                        />
                    )}
                    {activeTab === 1 && <Bar dataKey={yDataKey} fill='#673AB7' />}
                    {activeTab === 2 && <Bar dataKey={yDataKey} fill='#2196f3' />}
                    {activeTab === 3 && <Bar dataKey={yDataKey} fill='#2196f3' />}
                    {activeTab === 4 && <Bar dataKey={yDataKey} fill='#673AB7' />}
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
};

export default Chart;
