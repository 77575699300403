import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, styled } from '@mui/material';
import DetailsSkeleton from './satisfactionSkeleton';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ReducersModal } from 'utils/modals';
import USER_IMG from 'assets/images/user-image.png';
import ContainedButton from 'components/button/contained-button';
import ROUTE_NAMES from 'routes/routeNames';
import ReportedDetailsTable from 'components/tables/customerSatisfaction/flaggedReportedDetailsTable';
import { getFirebaseMessageDetails } from 'utils/constants';
import {
    UPDATE_CLIENT_DETAIL_REDUCER,
    UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
} from 'store/actionTypes';
import { blockUnblock, getClientDetail } from 'modules/clientManagement/action';
import { blockUnblock as blockUnblockVendor } from 'modules/photographersManagement/action';
import { getPhotographerDetail } from 'modules/photographersManagement/action';
import { sendWarningMail } from '../action';
import Loader from 'components/loader';
const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    backgroundColor: '#ffffff',
    padding: '10px 0px 10px 18px',
    display: 'flex',
    overflow: 'auto',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
    paddingRight: '0px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '17px',
    width: '403px',
}));

const ReceiverLineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '17px',
    width: '275px',
}));

const FlaggedAccountDetail = () => {
    const {
        bookingDetails,
        flaggedMessages,
        clientDetails,
        vendorDetails,
        clientFlaggedCount,
        vendorFlaggedCount,
    } = useSelector((state: ReducersModal) => state.FlaggedAccountReducer);
    const { totalBookings, location, status } = useSelector(
        (state: ReducersModal) => state.PhotographerDetailReducer,
    );
    const {
        totalBookings: clientTotalBooking,
        location: clientLocation,
        status: clientStatus,
    } = useSelector((state: ReducersModal) => state.ClientDetailReducer);
    const dispatch: any = useDispatch();
    const navigate: any = useNavigate();

    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const flaggedId = new URLSearchParams(window.location.search).get('id') || '';
    const senderType: any = new URLSearchParams(window.location.search).get('senderType') || 0;
    const dispatchClientValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_DETAIL_REDUCER,
            payload,
        });
    };

    const dispatchVendorValue = (payload: any) => {
        dispatch({
            type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
            payload,
        });
    };

    useLayoutEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        getFirebaseMessageDetails(flaggedId, parseInt(senderType), dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (clientDetails?.id) {
            dispatchClientValue({ clientId: clientDetails.id });
            dispatch(getClientDetail());
        }
        if (vendorDetails?.id) {
            dispatch(
                getPhotographerDetail(vendorDetails.id, () => {
                    setLoading(false);
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientDetails?.id, vendorDetails?.id, status, clientStatus]);

    const renderHeading = (label: string) => {
        return (
            <Typography
                variant='h3'
                component={'h3'}
                sx={{
                    width: parseInt(senderType) === 1 ? 'unset' : '190px',
                    minWidth: parseInt(senderType) === 1 ? '130px' : 'unset',
                    maxWidth: parseInt(senderType) === 1 ? '190px' : 'unset',
                    fontSize: '17px',
                }}>
                {label}
            </Typography>
        );
    };

    const renderLocationHeading = (label: string, type: number) => {
        return (
            <Typography
                variant='h3'
                component={'h3'}
                sx={{
                    width: type === 1 ? (parseInt(senderType) === 1 ? 'unset' : '190px') : '150px',
                    minWidth:
                        type === 1 ? (parseInt(senderType) === 1 ? '130px' : '190px') : 'unset',
                    maxWidth: type === 1 ? '190px' : 'unset',
                    fontSize: '17px',
                }}>
                {label}
            </Typography>
        );
    };

    const renderReceiverHeading = (label: string, type: number) => {
        return (
            <Typography
                variant='h3'
                component={'h3'}
                sx={{ width: type === 1 ? '110px' : '150px', fontSize: '17px' }}>
                {label}
            </Typography>
        );
    };

    const renderValue = (value: string | number, address: number) => {
        return (
            <Typography variant='h4' component={'h4'} sx={[styles.textCss, { fontSize: '16px' }]}>
                {value}
                {address ? ',' : ''}
            </Typography>
        );
    };

    const renderEmailValue = (value: string | number) => {
        return (
            <Typography
                variant='h4'
                component={'h4'}
                sx={[styles.textCss, { fontSize: '16px', minWidth: '100px', maxWidth: '200px' }]}>
                {value}
            </Typography>
        );
    };

    const renderProfilePic = (userType: number) => {
        let imgSrc = USER_IMG;
        if (userType === 1) {
            if (clientDetails?.picUrl) {
                imgSrc = clientDetails?.picUrl;
            }
        } else if (userType === 2) {
            if (vendorDetails?.picUrl) {
                imgSrc = vendorDetails?.picUrl;
            }
        }
        return (
            <Box sx={[styles.flexContainer, styles.profileContainer]}>
                <Box sx={styles.profileImgCon} id={'user-img'}>
                    <img src={imgSrc} style={{ height: '100%', width: '100%' }} alt='User-img' />
                </Box>
            </Box>
        );
    };

    /**
     * function to handle block unblock press
     * @param status
     *
     */

    const onBlockUnBlockPress = () => {
        let toBlockId = parseInt(senderType) === 1 ? clientDetails.id : vendorDetails.id;
        let newStatus =
            parseInt(senderType) === 1 ? (clientStatus === 3 ? 2 : 3) : status === 3 ? 2 : 3;
        let endPoint =
            parseInt(senderType) === 1
                ? `/status?clientId=${toBlockId}&status=${newStatus}`
                : `/status?vendorId=${toBlockId}&status=${newStatus}`;
        setActionLoading(true);
        if (parseInt(senderType) === 1) {
            dispatch(
                blockUnblock(endPoint, (res: any) => {
                    if (res.isSuccess) {
                        dispatchClientValue({
                            status: clientStatus === 3 ? 2 : 3,
                        });
                    }
                    setActionLoading(false);
                }),
            );
        } else {
            dispatch(
                blockUnblockVendor(endPoint, (res: any) => {
                    if (res.isSuccess) {
                        dispatchVendorValue({
                            status: status === 3 ? 2 : 3,
                        });
                    }
                    setActionLoading(false);
                }),
            );
        }
    };

    const renderLocation = (location: any, type: number) => {
        return (
            <LineContainer>
                {renderLocationHeading('Location', type)}
                {location?.street || location?.city || location?.postalCode ? (
                    <Typography
                        variant='h4'
                        component={'h4'}
                        sx={{ ...styles.value_text, maxWidth: '450px' }}>
                        {`${location?.street}${location?.street && location?.city && ','}  ${
                            location?.city
                        } 
                         ${location?.postalCode}`}
                    </Typography>
                ) : (
                    <Typography
                        variant='h4'
                        component={'h4'}
                        sx={{ ...styles.value_text, maxWidth: '450px' }}>
                        {'N/A'}
                    </Typography>
                )}
            </LineContainer>
        );
    };
    const statusToShow = (status: number) => {
        if (status === 3) {
            if (parseInt(senderType) === 1) {
                return 'Unblock Client';
            } else {
                return 'Unblock Photographer';
            }
        } else {
            if (parseInt(senderType) === 1) {
                return 'Block Client';
            } else {
                return 'Block Photographer';
            }
        }
    };
    const renderDetails = () => {
        return (
            <React.Fragment>
                <Box sx={[styles.flaggedHeadContainer, { marginBottom: '30px' }]}>
                    <LineContainer>
                        <Typography variant='h3' component={'h3'} sx={{ fontSize: '18px' }}>
                            Booking Id :
                        </Typography>
                        <Typography
                            variant='h4'
                            component={'h4'}
                            sx={[styles.textCss, { marginLeft: '4px' }]}>
                            {bookingDetails?.bookingRef}
                        </Typography>
                    </LineContainer>
                    <ContainedButton
                        onClick={() => {
                            if (parseInt(senderType) === 1) {
                                navigate(
                                    `${ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_BOOKING_DETAIL}?id=${bookingDetails?.bookingId}`,
                                );
                            } else if (parseInt(senderType) === 2) {
                                navigate(
                                    `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_BOOKING_DETAIL}?id=${bookingDetails?.bookingId}`,
                                );
                            }
                        }}
                        title={'Booking Details'}
                    />
                </Box>
                <Box sx={styles.flaggedHeadContainer}>
                    <Typography sx={{ color: '#673ab7' }} variant='h3' component='h3'>
                        {'Sender’s Profile Overview'}
                    </Typography>
                    <Box sx={styles.flexContainer}>
                        <ContainedButton
                            externalStyles={{
                                marginRight: '20px',
                            }}
                            onClick={() => {
                                if (parseInt(senderType) === 1) {
                                    navigate(
                                        `${ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_DETAIL}?id=${clientDetails?.id}`,
                                    );
                                } else {
                                    navigate(
                                        `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${vendorDetails?.id}`,
                                    );
                                }
                            }}
                            title={'View Profile'}
                        />
                        <ContainedButton
                            title={statusToShow(parseInt(senderType) === 1 ? clientStatus : status)}
                            onClick={() => onBlockUnBlockPress()}
                        />
                    </Box>
                </Box>
                <TableContainer sx={{ display: 'block' }}>
                    {vendorDetails && (
                        <ItemContainer key={vendorDetails.id}>
                            <Box>{renderProfilePic(parseInt(senderType) === 1 ? 1 : 2)}</Box>
                            <Box sx={[styles.columnStyle]}>
                                <Box sx={[styles.columnStyle, { flexDirection: 'row' }]}>
                                    <Box sx={styles.columnStyle}>
                                        <LineContainer>
                                            {renderHeading(
                                                parseInt(senderType) === 1
                                                    ? 'Client Name'
                                                    : 'Photographer Name',
                                            )}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? clientDetails?.fullName
                                                    : vendorDetails?.fullName,
                                                0,
                                            )}
                                        </LineContainer>
                                        <LineContainer>
                                            {renderHeading(
                                                parseInt(senderType) === 1
                                                    ? 'Client Email'
                                                    : 'Photographer Email',
                                            )}
                                            {renderEmailValue(
                                                parseInt(senderType) === 1
                                                    ? clientDetails?.email
                                                    : vendorDetails?.email,
                                            )}
                                        </LineContainer>
                                    </Box>
                                    <Box sx={[styles.columnStyle, { width: '295px' }]}>
                                        <ReceiverLineContainer>
                                            {renderReceiverHeading('Flagged Count', 0)}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? clientFlaggedCount
                                                    : vendorFlaggedCount,
                                                0,
                                            )}
                                        </ReceiverLineContainer>
                                        <ReceiverLineContainer>
                                            {renderReceiverHeading('Phone Number', 0)}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? clientDetails?.phoneNumber
                                                    : vendorDetails?.phoneNumber,
                                                0,
                                            )}
                                        </ReceiverLineContainer>
                                    </Box>
                                    <Box>
                                        <LineContainer sx={{ width: '145px' }}>
                                            {renderReceiverHeading('Bookings', 1)}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? clientTotalBooking
                                                    : totalBookings,
                                                0,
                                            )}
                                        </LineContainer>
                                    </Box>
                                </Box>
                                {renderLocation(
                                    parseInt(senderType) === 1 ? clientLocation : location,
                                    1,
                                )}
                            </Box>
                        </ItemContainer>
                    )}
                </TableContainer>
                <Box sx={styles.flaggedHeadContainer}>
                    <Typography sx={{ color: '#673ab7' }} variant='h3' component='h3'>
                        {'Receiver’s Profile Overview'}
                    </Typography>
                    <ContainedButton
                        onClick={() => {
                            if (parseInt(senderType) === 1) {
                                navigate(
                                    `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${vendorDetails?.id}`,
                                );
                            } else {
                                navigate(
                                    `${ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_DETAIL}?id=${clientDetails?.id}`,
                                );
                            }
                        }}
                        title={'View Profile'}
                    />
                </Box>
                <TableContainer sx={{ display: 'block' }}>
                    {clientDetails && (
                        <ItemContainer key={clientDetails.id}>
                            <Box>{renderProfilePic(parseInt(senderType) === 1 ? 2 : 1)}</Box>
                            <Box sx={[styles.columnStyle]}>
                                <Box sx={[styles.columnStyle, { flexDirection: 'row' }]}>
                                    <Box sx={styles.columnStyle}>
                                        <LineContainer>
                                            {renderReceiverHeading('Receiver Name', 0)}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? vendorDetails?.fullName
                                                    : clientDetails?.fullName,
                                                0,
                                            )}
                                        </LineContainer>
                                        <LineContainer>
                                            {renderReceiverHeading('Receiver Email', 0)}
                                            {renderEmailValue(
                                                parseInt(senderType) === 1
                                                    ? vendorDetails?.email
                                                    : clientDetails?.email,
                                            )}
                                        </LineContainer>
                                    </Box>
                                    <Box sx={[styles.columnStyle, { width: '295px' }]}>
                                        <ReceiverLineContainer>
                                            {renderReceiverHeading('Flagged Count', 0)}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? vendorFlaggedCount
                                                    : clientFlaggedCount,
                                                0,
                                            )}
                                        </ReceiverLineContainer>
                                        <ReceiverLineContainer>
                                            {renderReceiverHeading('Phone Number', 0)}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? vendorDetails?.phoneNumber
                                                    : clientDetails?.phoneNumber,
                                                0,
                                            )}
                                        </ReceiverLineContainer>
                                    </Box>
                                    <Box>
                                        <LineContainer sx={{ width: '145px' }}>
                                            {renderReceiverHeading('Bookings', 1)}
                                            {renderValue(
                                                parseInt(senderType) === 1
                                                    ? totalBookings
                                                    : clientTotalBooking,
                                                0,
                                            )}
                                        </LineContainer>
                                    </Box>
                                </Box>
                                {renderLocation(
                                    parseInt(senderType) === 2 ? clientLocation : location,
                                    2,
                                )}
                            </Box>
                        </ItemContainer>
                    )}
                </TableContainer>
                <Box sx={styles.flaggedHeadContainer}>
                    <Typography sx={{ color: '#673ab7' }} variant='h3' component='h3'>
                        {'Reported Details '}
                    </Typography>
                </Box>
                <ReportedDetailsTable
                    renderData={flaggedMessages}
                    senderType={senderType}
                    bookingId={bookingDetails?.bookingId}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {' '}
                    <ContainedButton
                        externalStyles={{
                            margin: '20px 0px',
                            alignSelf: 'center',
                            width: '210px',
                        }}
                        onClick={() => {
                            setActionLoading(true);
                            let data = {
                                senderId:
                                    parseInt(senderType) === 1
                                        ? vendorDetails?.id
                                        : clientDetails?.id,
                                bookingId: bookingDetails?.bookingId,
                            };
                            dispatch(
                                sendWarningMail(data, () => {
                                    setActionLoading(false);
                                }),
                            );
                        }}
                        title={'Send Warning Mail'}
                    />
                </Box>
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loading) {
            return <DetailsSkeleton />;
        }
        return renderDetails();
    };

    return (
        <Box sx={[styles.paper, { padding: 0 }]}>
            <Box sx={styles.container}>
                {renderView()}
                {actionLoading && <Loader />}
            </Box>
        </Box>
    );
};

export default FlaggedAccountDetail;
