import { styles } from '../styles';
import { Box, styled } from '@mui/material';
import ContainedButton from 'components/button/contained-button';
import Chart from '../graphRepresentation/chart';
import DurationFilter from '../durationFilter';
const ClientPhotoChart = ({
    activeTab,
    filteredData,
    setActiveTab,
    durationState,
    onDurationFilter,
    totalActiveClient,
    totalActivePhotographer,
}: any) => {
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        padding: '0px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));
    const xDataKey =
        durationState === 1
            ? 'date'
            : durationState === 2
            ? 'day'
            : durationState === 3
            ? 'date'
            : 'year';
    const xDataLabel =
        durationState === 1
            ? 'Today'
            : durationState === 2
            ? 'Week'
            : durationState === 3
            ? 'Month'
            : 'Year';
    const yDataKey = 'count';
    return (
        <ItemContainer>
            <Box sx={[styles.reportAndAnalytics, { padding: '20px' }]}>
                <Box sx={[styles.reportAndAnalytics, styles.toogleContainer]}>
                    {activeTab === 1 ? (
                        <ContainedButton
                            onClick={() => {
                                setActiveTab(1);
                            }}
                            btnType='graphBtn'
                            count={totalActiveClient}
                            externalStyles={styles.toogleBtn}
                            title={'Client'}
                        />
                    ) : (
                        <Box
                            onClick={() => setActiveTab(1)}
                            sx={[styles.toogleBtn, styles.paddingCss]}>
                            Client <span style={{ marginLeft: '20px' }}>{totalActiveClient}</span>
                        </Box>
                    )}
                    {activeTab === 2 ? (
                        <ContainedButton
                            onClick={() => {
                                setActiveTab(2);
                            }}
                            btnType='graphBtn'
                            count={totalActivePhotographer}
                            externalStyles={styles.btn}
                            title={'Photographer'}
                        />
                    ) : (
                        <Box
                            onClick={() => setActiveTab(2)}
                            sx={[styles.toogleBtn, styles.paddingCss]}>
                            Photographer
                            <span style={{ marginLeft: '20px' }}>{totalActivePhotographer}</span>
                        </Box>
                    )}
                </Box>
                <DurationFilter
                    durationState={durationState}
                    filterKey={1}
                    onDurationFilter={onDurationFilter}
                />
            </Box>
            <Box sx={styles.chartDimension}>
                <Chart
                    durationState={durationState}
                    result={filteredData}
                    activeTab={activeTab}
                    xDataKey={xDataKey}
                    yDataKey={yDataKey}
                    yLabel={activeTab === 1 ? 'Active Clients' : 'Active Photographer'}
                    xLabel={xDataLabel}
                />
            </Box>
        </ItemContainer>
    );
};

export default ClientPhotoChart;
