import React, { useEffect, useLayoutEffect, useState } from 'react';
import { styles } from './styles';
import Loader from 'components/loader';
import { Skeleton, Box } from '@mui/material';
import {
    InstantVsScheduleEarningModal,
    MostBookedPhotographerModal,
    MostPopularCategoryModal,
    PhotographyVsVideographyModal,
    ReducersModal,
    TopRatedPhotographerModal,
    topActiveClientModal,
    // BookingVsTipsEarningModal,
    // TotalBookingVsTipsModal,
    MostBookedLocationModal,
    AverageCommonModal,
} from 'utils/modals';
import { useSelector, useDispatch } from 'react-redux';
import ClientPhotoChart from './reports/clientPhotoChart';
import MostPopularCategory from './reports/mostPopularCategory';
import InstantVsScheduleEarning from './reports/instantVsSchedule';
import {
    getActiveClientPhotographerData,
    getAverageHourlyRateData,
    getAveragePhotoShootDurationData,
    getAveragePhotoShootCostData,
    getInstantScheduleData,
    getMostBookedLocationData,
    getMostBookedPhotographerData,
    getMostBookedPriceRangeData,
    getPhotographyVsVideographyData,
    getPopularCategoryData,
    getTopActiveClientData,
    getTopRatedPhotographerData,
    // getTotalBookingVsTipsData,
    // getBookingVsTipEarningData,
} from './action';
import { UPDATE_REPORTS_DATA_REDUCER } from 'store/actionTypes';
import MostBookedPhotographer from './reports/mostBookedPhotographer';
import PhotographyVsVideographyEarn from './reports/photographyVsVideographyEarn';
import TopActiveClient from './reports/topActiveClient';
import TopRatedPhotographer from './reports/topRatedPhotographer';
// import TotalBookingVsTips from './reports/totalBookingsVsTips';
// import BookingVsTipEarning from './reports/bookingVsTipEarning';
import AverageHourlyRate from './reports/averHourlyRate';
import AverageShootCost from './reports/averShootCost';
import MostBookedPriceRange from './reports/mostBookedPriceRange';
import AverageShootDuration from './reports/averShootDuration';
import MostBookedLocation from './reports/mostBookedLocation';
import MostBookedCategory from './reports/mostBookedCategory';
const ReportsAndAnalytics = () => {
    const {
        activeClient,
        activePhotographer,
        loader,
        mostPopularCategory,
        instantVsScheduleEarn,
        mostBookedPhotographer,
        photographyVsVideographyEarn,
        topActiveClient,
        topRatedPhotographer,
        // totalBookingVsTipsData,
        // bookingVsTipsEarning,
        avrHourlyRate,
        avrShootCost,
        avrShootDuration,
        mostBookedLocation,
        mostBookedPriceRange,
        averageCost,
        averageDuration,
        averageHourlyCharges,
        bookedPriceRange,
    } = useSelector((state: ReducersModal) => state.ReportsAndAnalyticsReducer);
    const [activeTab, setActiveTab] = useState(1);
    const [totalActiveClient, setTotalActiveClient] = useState(0);
    const [totalActivePhotographer, settotalActivePhotographer] = useState(0);
    const [filteredActiveClientData, setfilteredActiveClientData] = useState([]);
    const [filteredActivePhotographerData, setfilteredActivePhotographerData] = useState([]);
    const [filteredPopularCategory, setFilteredPopularCategory] = useState<
        MostPopularCategoryModal[]
    >([]);
    const [filteredInstantVsSchedule, setFilteredInstantVsSchedule] = useState<
        InstantVsScheduleEarningModal[]
    >([]);

    const [filteredTopActiveClient, setFilteredTopActiveClient] = useState<topActiveClientModal[]>(
        [],
    );
    const [filteredPhotographyVsVideography, setFilteredPhotographyVsVideography] = useState<
        PhotographyVsVideographyModal[]
    >([]);
    const [filteredBookedPhotographer, setFilteredBookedPhotographer] = useState<
        MostBookedPhotographerModal[]
    >([]);
    const [filteredTopRatedPhotographer, setFilteredTopRatedPhotographer] = useState<
        TopRatedPhotographerModal[]
    >([]);
    //     const [filteredTotalBookingVsTips, setFilteredTotalBookingVsTips] = useState<
    //         TotalBookingVsTipsModal[]
    //     >([]);
    //     const [filteredBookingVsTipsData, setFilteredBookingVsTipsData] = useState<
    //     BookingVsTipsEarningModal[]
    // >([]);
    const [filteredAvrShootCost, setFilteredAvrShootCost] = useState<AverageCommonModal[]>([]);
    const [filteredAvrShootDuration, setFilteredAvrShootDuration] = useState<AverageCommonModal[]>(
        [],
    );
    const [filteredAvrHourlyRate, setFilteredAvrHourlyRate] = useState<AverageCommonModal[]>([]);
    const [filteredMostBookedLocation, setFilteredMostBookedLocation] = useState<
        MostBookedLocationModal[]
    >([]);
    const [filteredMostBookedPriceRange, setFilteredMostBookedPriceRange] = useState<
        AverageCommonModal[]
    >([]);
    const [filteredMostBookedCategory, setFilteredMostBookedCategory] = useState<
        MostPopularCategoryModal[]
    >([]);
    const [clientPhotographerState, setClientPhotographerState] = useState(4);
    const [mostPopularCategoryState, setMostPopularCategoryState] = useState(4);
    const [instantVsScheduleState, setInstantVsScheduleState] = useState(4);
    const [mostBookedPhotographerState, setMostBookedPhotographerState] = useState(4);
    const [photoVsVideographyState, setPhotoVsVideographyState] = useState(4);
    const [topActiveClientState, setTopActiveClientState] = useState(4);
    const [topRatedPhotographerState, setTopRatedPhotographerState] = useState(4);
    // const [totalBookingVsTipsState, settotalBookingVsTipsState] = useState(4);
    // const [bookingVsTipsState, setBookingVsTipsState] = useState(4);
    const [avrShootCostState, setAvrShootCostState] = useState(4);
    const [avrHourlyRateState, setAvrHourlyRateState] = useState(4);
    const [avrShootDurationState, setAvrShootDurationState] = useState(4);
    const [mostBookedPriceRangeState, setMostBookedPriceRangeState] = useState(4);
    const dispatch: any = useDispatch();
    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload,
        });
    };
    useLayoutEffect(() => {
        dispatchValue({
            activeClient: [],
            activePhotographer: [],
            instantVsScheduleEarn: [],
            mostPopularCategory: [],
            avrShootCost: [],
            avrHourlyRate: [],
            avrShootDuration: [],
            photographyVsVideographyEarn: [],
            mostBookedPhotographer: [],
            mostBookedPriceRange: [],
            mostBookedLocation: [],
            topRatedPhotographer: [],
            topActiveClient: [],
            // totalBookingVsTipsData: [],
            // bookingVsTipsEarning: [],
            averageCost: 0,
            averageHourlyCharges: 0,
            bookedPriceRange: '',
            averageDuration: '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const urls: any = [
            dispatch(getActiveClientPhotographerData(clientPhotographerState, 1)),
            dispatch(getActiveClientPhotographerData(clientPhotographerState, 2)),
            dispatch(getPopularCategoryData(mostPopularCategoryState)),
            dispatch(getTopActiveClientData(topActiveClientState)),
            dispatch(getInstantScheduleData(instantVsScheduleState)),
            dispatch(getPhotographyVsVideographyData(photoVsVideographyState)),
            dispatch(getAveragePhotoShootCostData(avrShootCostState)),
            dispatch(getAverageHourlyRateData(avrHourlyRateState)),
            dispatch(getMostBookedPriceRangeData(mostBookedPriceRangeState)),
            dispatch(getAveragePhotoShootDurationData(avrShootDurationState)),
            dispatch(getMostBookedPhotographerData(mostBookedPhotographerState)),
            dispatch(getTopRatedPhotographerData(topRatedPhotographerState)),
            // dispatch(getTotalBookingVsTipsData(totalBookingVsTipsState)),
            // dispatch(getBookingVsTipEarningData(bookingVsTipsState)),
            dispatch(getMostBookedLocationData(4)),
        ];
        Promise.allSettled(urls)
            .then(() => {})
            .catch((error) => {
                dispatchValue({
                    loading: false,
                });
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredInstantVsSchedule(instantVsScheduleEarn);
    }, [instantVsScheduleEarn]);

    useEffect(() => {
        setFilteredPopularCategory(mostPopularCategory);
        setFilteredMostBookedCategory(mostPopularCategory);
    }, [mostPopularCategory]);

    useEffect(() => {
        setFilteredBookedPhotographer(mostBookedPhotographer);
    }, [mostBookedPhotographer]);

    useEffect(() => {
        setFilteredPhotographyVsVideography(photographyVsVideographyEarn);
    }, [photographyVsVideographyEarn]);

    useEffect(() => {
        let uniqueData: any = [];
        if (topActiveClient?.length > 0) {
            uniqueData = topActiveClient?.splice(1, topActiveClient?.length);
        } else {
            uniqueData = topActiveClient;
        }
        setFilteredTopActiveClient(uniqueData);
    }, [topActiveClient]);

    useEffect(() => {
        setFilteredTopRatedPhotographer(topRatedPhotographer);
    }, [topRatedPhotographer]);

    useEffect(() => {
        setFilteredAvrHourlyRate(avrHourlyRate);
    }, [avrHourlyRate]);

    useEffect(() => {
        setFilteredAvrShootCost(avrShootCost);
    }, [avrShootCost]);

    useEffect(() => {
        setFilteredAvrShootDuration(avrShootDuration);
    }, [avrShootDuration]);

    useEffect(() => {
        setFilteredMostBookedLocation(mostBookedLocation);
    }, [mostBookedLocation]);

    useEffect(() => {
        setFilteredMostBookedPriceRange(mostBookedPriceRange);
    }, [mostBookedPriceRange]);

    useEffect(() => {
        let uniqueData: any = [];
        if (activePhotographer?.length > 0 && clientPhotographerState !== 1) {
            uniqueData = activePhotographer?.splice(1, activePhotographer?.length);
        } else {
            uniqueData = activePhotographer;
        }
        let totalCount = 0;
        totalCount = uniqueData?.reduce(function (acc: any, obj: any) {
            return acc + obj.count;
        }, 0);
        settotalActivePhotographer(totalCount);
        setfilteredActivePhotographerData(uniqueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePhotographer]);

    useEffect(() => {
        let uniqueData: any = [];
        if (activeClient?.length > 0 && clientPhotographerState !== 1) {
            uniqueData = activeClient?.splice(1, activeClient?.length);
        } else {
            uniqueData = activeClient;
        }
        let totalCount = 0;
        totalCount = uniqueData?.reduce(function (acc: any, obj: any) {
            return acc + obj.count;
        }, 0);
        setTotalActiveClient(totalCount);
        setfilteredActiveClientData(uniqueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeClient]);

    // useEffect(() => {
    //     setFilteredTotalBookingVsTips(totalBookingVsTipsData);
    // }, [totalBookingVsTipsData]);

    // useEffect(() => {
    //     let uniqueData: any = [];
    //     if (bookingVsTipsEarning?.length > 0) {
    //         uniqueData = bookingVsTipsEarning?.splice(1, bookingVsTipsEarning?.length);
    //     } else {
    //         uniqueData = bookingVsTipsEarning;
    //     }
    //     setFilteredBookingVsTipsData(uniqueData);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [bookingVsTipsEarning]);

    const onDurationFilter = (e: any, filterKey: number) => {
        if (filterKey === 1) {
            dispatch(getActiveClientPhotographerData(e.target.value, 1));
            dispatch(getActiveClientPhotographerData(e.target.value, 2));
            setClientPhotographerState(parseInt(e.target.value));
            if (activeTab === 1) {
                setfilteredActiveClientData([]);
            } else {
                setfilteredActivePhotographerData([]);
            }
        } else if (filterKey === 2) {
            dispatch(getPopularCategoryData(parseInt(e.target.value)));
            setMostPopularCategoryState(parseInt(e.target.value));
        } else if (filterKey === 3) {
            dispatch(getTopActiveClientData(parseInt(e.target.value)));
            setTopActiveClientState(parseInt(e.target.value));
        } else if (filterKey === 4) {
            dispatch(getInstantScheduleData(parseInt(e.target.value)));
            setInstantVsScheduleState(parseInt(e.target.value));
        } else if (filterKey === 5) {
            dispatch(getPhotographyVsVideographyData(parseInt(e.target.value)));
            setPhotoVsVideographyState(parseInt(e.target.value));
        } else if (filterKey === 6) {
            dispatch(getMostBookedPhotographerData(parseInt(e.target.value)));
            setMostBookedPhotographerState(parseInt(e.target.value));
        } else if (filterKey === 7) {
            dispatch(getTopRatedPhotographerData(parseInt(e.target.value)));
            setTopRatedPhotographerState(parseInt(e.target.value));
        }
        //  else if (filterKey === 8) {
        //     dispatch(getTotalBookingVsTipsData(parseInt(e.target.value)));
        //     settotalBookingVsTipsState(parseInt(e.target.value));
        // } else if (filterKey === 9) {
        //     dispatch(getBookingVsTipEarningData(parseInt(e.target.value)));
        //     setBookingVsTipsState(parseInt(e.target.value));
        // }
        else if (filterKey === 10) {
            dispatch(getAverageHourlyRateData(parseInt(e.target.value)));
            setAvrHourlyRateState(parseInt(e.target.value));
        } else if (filterKey === 11) {
            dispatch(getAveragePhotoShootDurationData(parseInt(e.target.value)));
            setAvrShootDurationState(parseInt(e.target.value));
        } else if (filterKey === 12) {
            dispatch(getAveragePhotoShootCostData(parseInt(e.target.value)));
            setAvrShootCostState(parseInt(e.target.value));
        } else if (filterKey === 13) {
            dispatch(getMostBookedPriceRangeData(parseInt(e.target.value)));
            setMostBookedPriceRangeState(parseInt(e.target.value));
        }
    };

    const onTabChange = (tab: number) => {
        setActiveTab(tab);
    };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <Box sx={styles.reportAndAnalytics}>
                    <Skeleton
                        height={48}
                        width={300}
                        variant='rectangular'
                        sx={{ borderRadius: '12px' }}
                    />
                    <Skeleton
                        height={42}
                        width={160}
                        variant='rectangular'
                        sx={{ borderRadius: '4px' }}
                    />
                </Box>
                <Skeleton
                    height={500}
                    width={'100%'}
                    variant='rectangular'
                    sx={{ borderRadius: '8px', marginTop: '16px' }}
                />
            </React.Fragment>
        );
    };

    const checkIfAnyDataExist = () => {
        if (mostPopularCategory.length === 0 && InstantVsScheduleEarning.length === 0) {
            return false;
        }
        return true;
    };

    const renderView = () => {
        if (loader && !checkIfAnyDataExist()) {
            return renderLoading();
        }
        return (
            <Box sx={[styles.paper, { padding: '16px' }]}>
                <ClientPhotoChart
                    activeTab={activeTab}
                    filteredData={
                        activeTab === 1 ? filteredActiveClientData : filteredActivePhotographerData
                    }
                    totalActiveClient={totalActiveClient}
                    totalActivePhotographer={totalActivePhotographer}
                    setActiveTab={onTabChange}
                    durationState={clientPhotographerState}
                    onDurationFilter={onDurationFilter}
                />
                <Box sx={[styles.reportAndAnalytics, { marginTop: '20px' }]}>
                    <AverageShootCost
                        filteredAvrShootCost={filteredAvrShootCost}
                        onDurationFilter={onDurationFilter}
                        avrShootCostState={avrShootCostState}
                        averageCost={averageCost}
                    />
                    <AverageHourlyRate
                        filteredAvrHourlyRate={filteredAvrHourlyRate}
                        onDurationFilter={onDurationFilter}
                        avrHourlyRateState={avrHourlyRateState}
                        averageRate={averageHourlyCharges}
                    />
                </Box>
                <Box sx={[styles.reportAndAnalytics, { marginTop: '20px' }]}>
                    <MostBookedPriceRange
                        filteredMostBookedPriceRange={filteredMostBookedPriceRange}
                        onDurationFilter={onDurationFilter}
                        mostBookedPriceRangeState={mostBookedPriceRangeState}
                        bookedPriceRange={bookedPriceRange}
                    />
                    <AverageShootDuration
                        filteredAvrShootDuration={filteredAvrShootDuration}
                        onDurationFilter={onDurationFilter}
                        avrShootDurationState={avrShootDurationState}
                        averageDuration={averageDuration}
                    />
                </Box>
                <Box sx={[styles.reportAndAnalytics, { marginTop: '20px' }]}>
                    <MostPopularCategory
                        filteredPopularCategory={filteredPopularCategory}
                        onDurationFilter={onDurationFilter}
                        mostPopularCategoryState={mostPopularCategoryState}
                    />
                    <TopActiveClient
                        filteredTopActiveClient={filteredTopActiveClient}
                        onDurationFilter={onDurationFilter}
                        topActiveClientState={topActiveClientState}
                    />
                </Box>
                <Box sx={[styles.reportAndAnalytics, { marginTop: '20px' }]}>
                    <InstantVsScheduleEarning
                        filteredInstantVsSchedule={filteredInstantVsSchedule}
                        instantVsScheduleState={instantVsScheduleState}
                        onDurationFilter={onDurationFilter}
                    />

                    <PhotographyVsVideographyEarn
                        filteredPhotographyVsVideography={filteredPhotographyVsVideography}
                        photoVsVideographyState={photoVsVideographyState}
                        onDurationFilter={onDurationFilter}
                    />
                </Box>
                <Box sx={[styles.reportAndAnalytics, { marginTop: '20px' }]}>
                    <MostBookedPhotographer
                        filteredBookedPhotographer={filteredBookedPhotographer}
                        onDurationFilter={onDurationFilter}
                        mostBookedPhotographerState={mostBookedPhotographerState}
                    />
                    <TopRatedPhotographer
                        filteredTopRatedPhotographer={filteredTopRatedPhotographer}
                        topRatedPhotographerState={topRatedPhotographerState}
                        onDurationFilter={onDurationFilter}
                    />
                </Box>
                {/* <TotalBookingVsTips
                    filteredTotalBookingVsTips={filteredTotalBookingVsTips}
                    totalBookingVsTipsState={totalBookingVsTipsState}
                    onDurationFilter={onDurationFilter}
                />
                <BookingVsTipEarning
                    filteredBookingVsTipsData={filteredBookingVsTipsData}
                    bookingVsTipsState={bookingVsTipsState}
                    onDurationFilter={onDurationFilter}
                /> */}
                <MostBookedLocation filteredMostBookedLocation={filteredMostBookedLocation} />
                <MostBookedCategory filteredMostBookedCategory={filteredMostBookedCategory} />
            </Box>
        );
    };

    return (
        <>
            {renderView()}
            {loader && <Loader />}
        </>
    );
};

export default ReportsAndAnalytics;
