import { ArrowDownward } from '@mui/icons-material';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonTableStyles as styles } from '../styles';
import { ManageDisputeData, ReducersModal } from 'utils/modals';
import {
    UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
    UPDATE_MANAGE_DISPUTE_REDUCER,
    UPDATE_PHOTOGRAPHERS_REDUCER,
} from 'store/actionTypes';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import { getManageAndDisputeListing } from 'modules/customerSatisfaction/action';
import { REQUEST_STATUS, commentList } from 'utils/enum';
import { blockUnblock } from 'modules/clientManagement/action';
import { blockUnblock as blockUnblockVendor } from 'modules/photographersManagement/action';

const ManageDisputeTable = ({ activeTab }: any) => {
    const { result, page, limit, total, senderType } = useSelector(
        (state: ReducersModal) => state.ManageDisputeReducer,
    );
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [isSort, setIsSort] = useState<{ [key: string]: boolean }>({
        createdAt: false,
        fullName: false,
    });

    const dispatchDisputeValue = (payload: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
            payload,
        });
    };

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_REDUCER,
            payload,
        });
    };

    const dispatchVendorValue = (payload: any) => {
        dispatch({
            type: UPDATE_PHOTOGRAPHERS_REDUCER,
            payload,
        });
    };

    const handleSort = (sortCol: string) => {
        const newObject: any = {};
        Object.keys(isSort).forEach(function sort(key) {
            if (key === sortCol) {
                newObject[key] = !isSort[key];
            } else {
                newObject[key] = false;
            }
        });
        setIsSort(newObject);
        dispatchValue({
            page: 1,
            sortedBy: sortCol,
            sortOrder: newObject[sortCol] ? '1' : '-1',
        });

        dispatch(getManageAndDisputeListing());
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchValue({ page: newPage + 1 });
        dispatch(getManageAndDisputeListing());
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatchValue({ limit: event.target.value, page: 1 });
        dispatch(getManageAndDisputeListing());
    };

    /**
     * function to handle block unblock press
     * @param status
     *
     */

    const onBlockUnBlockPress = (data: ManageDisputeData) => {
        let toBlockId = senderType === 1 ? data.clientDetails._id : data.vendorDetails._id;
        let newStatus =
            senderType === 1 ? (data.clientStatus === 3 ? 2 : 3) : data.vendorStatus === 3 ? 2 : 3;
        let endPoint =
            senderType === 1
                ? `/status?clientId=${toBlockId}&status=${newStatus}`
                : `/status?vendorId=${toBlockId}&status=${newStatus}`;
        if (senderType === 1) {
            dispatch(
                blockUnblock(endPoint, (res: any) => {
                    if (res.isSuccess) {
                        dispatchValue({ status: null });
                        dispatchDisputeValue({ clientStatus: data.clientStatus === 3 ? 2 : 3 });
                    }
                }),
            );
        } else {
            dispatch(
                blockUnblockVendor(endPoint, (res: any) => {
                    if (res.isSuccess) {
                        dispatchVendorValue({ status: null });
                        dispatchDisputeValue({ vendorStatus: data.vendorStatus === 3 ? 2 : 3 });
                    }
                }),
            );
        }
        // dispatchValue({
        //     toBlockId: data._id,
        //     status: data.blockStatus,
        // });
    };

    return (
        <Box sx={styles.paper}>
            <TableContainer>
                <Table sx={styles.table}>
                    <TableHead sx={{ ...styles.tableHeader_cnt_algn }}>
                        <TableRow sx={styles.headerRow}>
                            <TableCell>{'S.No.'}</TableCell>
                            <TableCell>{'Report ID'}</TableCell>
                            <TableCell>
                                {'Reported By'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['fullName'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('fullName')}
                                />
                            </TableCell>
                            <TableCell>
                                {'Date And Time'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['createdAt'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('createdAt')}
                                />
                            </TableCell>
                            <TableCell>{'Comment'}</TableCell>
                            <TableCell>{'Request Status'}</TableCell>
                            <TableCell className='action-table-header'>{'Action'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result && result.length > 0 ? (
                            result.map((val: any, idx: number) => {
                                let serialNumber = `${(page - 1) * limit + (idx + 1)}.`,
                                    userName = val?.clientDetails
                                        ? `${val.clientDetails?.fullName}`
                                        : `${val.vendorDetails?.fullName}`;
                                const statusToShow = (status: number) => {
                                    if (status === 3) {
                                        return 'Unblock';
                                    } else {
                                        return 'Block';
                                    }
                                };

                                return (
                                    <TableRow
                                        key={val._id}
                                        sx={{ ...styles.customer_satisfaction }}>
                                        <TableCell>{serialNumber}</TableCell>
                                        <TableCell>{val?.grievanceRef || 'N/A'}</TableCell>
                                        <TableCell title={userName}>{userName}</TableCell>
                                        <TableCell>
                                            {val?.createdAt
                                                ? moment(val.createdAt).format(
                                                      'MM/DD/YYYY, hh:mm A',
                                                  )
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell
                                            sx={{ textTransform: 'capitalize' }}
                                            title={commentList[val?.grievances]}>
                                            {commentList[val?.grievances] || 'N/A'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                            {val?.requestStatus
                                                ? REQUEST_STATUS[val?.requestStatus]
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={styles.action_table_cell_container}>
                                                <Button
                                                    className='action-view-btn'
                                                    onClick={() => {
                                                        navigate(
                                                            `${ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION_DETAIL}?id=${val._id}&senderType=${activeTab}`,
                                                        );
                                                    }}>
                                                    {'View'}
                                                </Button>
                                                <Button
                                                    className='action-view-btn'
                                                    onClick={() => onBlockUnBlockPress(val)}>
                                                    {statusToShow(
                                                        senderType === 1
                                                            ? val?.clientStatus
                                                            : val?.vendorStatus,
                                                    )}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={15} sx={styles.noDataCell}>
                                    <Box>{'No Data Found'}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={styles.paginationContainer}>
                <TablePagination
                    component='div'
                    count={total}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default ManageDisputeTable;
