import { ENDPOINTS } from 'utils/endpoints';
import { GET_API_CALL, POST_API_CALL_FILE_DOWNLOAD, PUT_API_CALL } from 'utils/services';
import { APIResponseModal } from 'utils/modals';
import {
    UPDATE_CLIENT_BOOKING_MADE_REDUCER,
    UPDATE_CLIENT_DETAIL_REDUCER,
    UPDATE_CLIENT_REDUCER,
} from 'store/actionTypes';
import SHOW_TOAST from 'utils/showToast';

export const getClientListing = () => {
    return (dispatch: Function, getState: Function) => {
        const {
            page,
            limit,
            search,
            sortedBy,
            sortOrder,
            toDate,
            fromDate,
            cityFilter,
            zipCodeFilter,
            clientStatus,
        } = getState().ClientReducer;

        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (clientStatus === 2 || clientStatus === 3) parameters += `clientStatus=${clientStatus}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (fromDate) parameters += `fromDate=${fromDate}&`;
        if (toDate) parameters += `toDate=${toDate}&`;
        if (cityFilter.length > 0) parameters += `city=${cityFilter.map((a: any) => a.city)}&`;
        if (zipCodeFilter.length > 0)
            parameters += `zipCode=${zipCodeFilter.map((a: any) => a.postalCode)}&`;

        dispatch({
            type: UPDATE_CLIENT_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.CLIENT_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_CLIENT_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_CLIENT_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

export const getClientDetail = () => {
    return (dispatch: Function, getState: Function) => {
        const { clientId } = getState().ClientDetailReducer;

        let parameters = '?';

        if (clientId) parameters += `clientId=${clientId}`;

        dispatch({
            type: UPDATE_CLIENT_DETAIL_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.CLIENT_MANAGEMENT.DETAIL,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_CLIENT_DETAIL_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_CLIENT_DETAIL_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

export const blockUnblock = (param: string, callback: any) => {
    return () => {
        PUT_API_CALL(
            `${ENDPOINTS.CLIENT_MANAGEMENT.BLOCK_UNBLOCK}/${param}`,
            {},
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                callback(response);
            },
        );
    };
};

/**
 * function to get reasons from api
 * @param type
 * @param callback
 * @returns
 */
export const getReason = (type: number, callback: Function) => {
    return () => {
        GET_API_CALL(
            ENDPOINTS.CLIENT_MANAGEMENT.BLOCK_UNBLOCK_REASON,
            `?type=${type}`,
            (response: APIResponseModal) => {
                let dataToReturn: string[] = [];
                if (response.isSuccess) {
                    const { data } = response;
                    for (let i = 0; i < Object.keys(data).length - 1; i += 1) {
                        dataToReturn.push(data[`${i}`]);
                    }
                }
                callback({ data: dataToReturn, isSuccess: response.isSuccess });
            },
        );
    };
};

/**
 * function to get city and zipcodes from api for filters
 * @param type
 * @param callback
 * @returns
 */
export const getCityZipCodes = (type: number, profileType: number, callback: Function) => {
    return () => {
        GET_API_CALL(
            ENDPOINTS.AUTH.CITY_ZIPCODES_LIST,
            `?type=${type}&profile=${profileType}&&page=1&limit=1000`,
            (response: APIResponseModal) => {
                callback(response);
            },
        );
    };
};

/**
 * function to get client Booking Made Listing
 * @param clientId
 * @param callback
 * @returns
 */
export const getClientBookingMadeListing = () => {
    return (dispatch: Function, getState: Function) => {
        const { page, limit, search, sortedBy, sortOrder, clientId } =
            getState().ClientVendorBookingMadeReducer;

        let parameters = '?';
        if (clientId) parameters += `clientId=${clientId}&`;
        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;

        dispatch({
            type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.CLIENT_MANAGEMENT.BOOKING_MADE,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 * function to get client Booking Made detail
 * @param bookingId
 * @param callback
 * @returns
 */
export const getClientBookingDetail = (_id: string | null, callback: Function) => {
    return () => {
        GET_API_CALL(
            ENDPOINTS.CLIENT_MANAGEMENT.BOOKING_MADE_DETAILS,
            _id || '',
            (response: APIResponseModal) => {
                callback(response);
            },
        );
    };
};

export const exportToCSV = (data: any, callback: Function) => {
    return () => {
        POST_API_CALL_FILE_DOWNLOAD(
            ENDPOINTS.CLIENT_MANAGEMENT.EXPORT_TO_CSV,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const message = 'CSV Downloaded Successfully. ';
                    SHOW_TOAST(message, 'success');
                }
                callback(response);
            },
        );
    };
};
