import { ENDPOINTS } from 'utils/endpoints';
import { GET_API_CALL } from 'utils/services';
import { APIResponseModal } from 'utils/modals';
import {
    UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER,
    UPDATE_EARN_AND_TRANSACTION_REDUCER,
} from 'store/actionTypes';

/**
 *
 * @returns Earn and transaction listing
 */
export const getEarnAdTransactionListing = () => {
    return (dispatch: Function, getState: Function) => {
        const {
            page,
            limit,
            search,
            sortedBy,
            sortOrder,
            toDate,
            fromDate,
            cityFilter,
            zipCodeFilter,
        } = getState().EarnAndTransactionReducer;

        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (fromDate) parameters += `fromDate=${fromDate}&`;
        if (toDate) parameters += `toDate=${toDate}&`;
        if (cityFilter.length > 0) parameters += `city=${cityFilter.map((a: any) => a.city)}&`;
        if (zipCodeFilter.length > 0)
            parameters += `zipCode=${zipCodeFilter.map((a: any) => a.postalCode)}&`;

        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param bookingId required to get transaction details corresponding to the booking
 * @param callback will trigger on the response of API success/failure
 * @returns will return transaction details of particular booking
 */
export const getEarnAdTransactionDetail = (bookingId: string, callback: Function) => {
    return (dispatch: Function) => {
        GET_API_CALL(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.DETAIL,
            `${bookingId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER,
                        payload: { ...response.data },
                    });
                }
                callback(response);
            },
        );
    };
};

/**
 *
 * @param dateFilter will be the duration for which report is required can be today/week/month/year
 * @returns will return reports data accordingly
 */

export const getTransactionReportData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                        payload: { reports: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};
