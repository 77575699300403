export const styles = {
    headContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0 10px 0',
        justifyContent: 'space-between',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainContainer: {
        top: '50%',
        width: 'fit-content',
        left: '50%',
        boxShadow: 24,
        height: 'auto',
        borderRadius: '12px',
        position: 'absolute',
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        transform: 'translate(-50%, -50%)',
    },
    heading: {
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        justifyContent: 'center',
        padding: '0px 10px 0px 10px',
        borderBottom: '1px solid lightGray',
    },
    heading_ac: {
        color: '#673ab7',
    },

    modal: {
        padding: '30px 20px 0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'column',
    },
    button: {
        padding: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '15px',
    },
    imageModalContainer: {
        top: '50%',
        width: 'fit-content',
        left: '50%',
        boxShadow: 24,
        height: 'auto',
        borderRadius: '12px',
        position: 'absolute',
        bgcolor: 'background.paper',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box',
    },
    closeIcon: {
        position: 'absolute',
        top: '6px',
        right: '10px',
        color: '#fff',
        cursor: 'pointer',
        zIndex: 200,
        height: '30px',
        width: '30px',
    },
    swiper: {
        maxWidth: 1200,
        boxSizing: 'border-box',
        height: '100vh',
        '& .swiper-button-prev.swiper-button-disabled': {
            color: (theme: any) => theme.palette.common.white,
            opacity: 1,
        },
        '& .swiper-button-next.swiper-button-disabled': {
            color: (theme: any) => theme.palette.common.white,
            opacity: 1,
        },
        '& .swiper-pagination-bullet': {
            background: (theme: any) => theme.palette.common.white,
            opacity: 1,
        },
        '& .swiper-pagination-bullet-active': {
            background: '#007aff',
            opacity: 1,
        },
    },
};
