import * as React from 'react';
import { styles } from './styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import { Formik } from 'formik';
import { ReducersModal } from 'utils/modals';
import { UserBlockedSchema } from 'utils/schema';
import { useDispatch, useSelector } from 'react-redux';
import ContainedButton from 'components/button/contained-button';
import { UPDATE_PHOTOGRAPHER_DETAIL_REDUCER } from 'store/actionTypes';
import { getRejectionReason, verifyVendor } from 'modules/photographersManagement/action';

interface Props {
    photographerId: string;
}

export default function RejectPhotographerPopup({ photographerId }: Props) {
    const dispatch: any = useDispatch();
    const { reasonData } = useSelector((state: ReducersModal) => state.PhotographerDetailReducer);

    const initialState = {
        reason: '',
        text: '',
    };

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
            payload,
        });
    };

    React.useEffect(() => {
        dispatch(getRejectionReason(2));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        dispatchValue({ showRejectPopup: false });
    };
    const handleOnSubmit = (values: any) => {
        const data = {
            status: 3,
            vendorId: photographerId,
            rejectionReason: values.reason,
        };
        dispatch(
            verifyVendor(data, () => {
                dispatchValue({ isVerified: 3 });
            }),
        );
    };

    return (
        <Modal
            open={true}
            aria-labelledby='modal-photographers-management-title'
            aria-describedby='modal-photographers-management-description'
        >
            <Box sx={styles.modal_container}>
                <Box sx={styles.headingBar}>
                    <Typography sx={styles.heading_ac} variant='h3' component='h2'>
                        {'Add reason of Rejection'}
                    </Typography>
                </Box>
                <Formik
                    onSubmit={handleOnSubmit}
                    initialValues={initialState}
                    validationSchema={UserBlockedSchema}
                >
                    {({ errors, handleChange, handleSubmit, touched, values }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <Box sx={styles.inputBox}>
                                    <FormControl fullWidth>
                                        <InputLabel id='test-select-phptographer-label'>
                                            {'Select Reason'}
                                        </InputLabel>
                                        <Select
                                            labelId='label-demo-simple-select-helper-photographer-label'
                                            id='demo-simple-select-photographer-helper'
                                            name='reason'
                                            value={values.reason}
                                            label='Select Reason'
                                            onChange={handleChange}
                                        >
                                            {reasonData.map((item: string) => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {touched.reason && errors.reason && (
                                            <FormHelperText
                                                error
                                                id='standard-weight-helper-text-photographers-management'
                                            >
                                                {errors.reason}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    {values.reason === 'Others' && (
                                        <FormControl
                                            fullWidth
                                            style={{
                                                marginTop: '50px',
                                            }}
                                        >
                                            <OutlinedInput
                                                value={values.text}
                                                onChange={handleChange}
                                                name='text'
                                                maxRows={6}
                                                minRows={6}
                                                multiline={true}
                                                placeholder='Write here...'
                                                inputProps={{
                                                    maxLength: 1000,
                                                    style: { fontWeight: 300 },
                                                }}
                                            />
                                            {touched.text && errors.text && (
                                                <FormHelperText
                                                    error
                                                    id='standard-weight-helper-text-vendor'
                                                >
                                                    {errors.text}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                </Box>

                                <Box sx={styles.button}>
                                    <ContainedButton
                                        onClick={handleClose}
                                        title={'Cancel'}
                                        fullWidth
                                    />
                                    <ContainedButton fullWidth type='submit' title={'Reject'} />
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Modal>
    );
}
