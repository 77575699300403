import { styles } from './styles';
import { Box, Rating, Typography, styled } from '@mui/material';
import PackagesSkeleton from './bookingSkeleton';
import React, { useEffect, useState } from 'react';
import USER_IMG from 'assets/images/user-image.png';
import LongMenu from './menu';
import { useDispatch } from 'react-redux';
import { APIResponseModal, ClientVendorBookingDetailModal } from 'utils/modals';
import { getVendorBookingDetail } from 'modules/photographersManagement/action';
import moment from 'moment';
import CALENDER_IMG from 'assets/images/calendar.png';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_BORDER,
    BOOKING_STATUS_CSS,
    BOOKING_STATUS_FONT,
} from 'utils/enum';
import { UPDATE_CLIENT_BOOKING_DETAIL_REDUCER } from 'store/actionTypes';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '12px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '18px 32px 5px 32px',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));
const CardContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
    paddingRight: '18px',
}));

const HeadingContainer = styled('div')(() => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid black',
    paddingLeft: '20px',
}));

const DetailContainer = styled('div')(() => ({
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '20px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
}));

const PhotographerBookingDetail = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<ClientVendorBookingDetailModal>();
    const bookingId = new URLSearchParams(window.location.search).get('id');

    //**Function to hit API to get Booking Details of Vendor */
    //**@props bookingID */
    //**@callBack Response data */
    //**Set Response data in State */

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_BOOKING_DETAIL_REDUCER,
            payload,
        });
    };

    const fetchVendorBookingDetails = () => {
        setLoading(true);
        dispatch(
            getVendorBookingDetail(bookingId, ({ isSuccess, data }: APIResponseModal) => {
                if (isSuccess) {
                    setListData({ ...data });
                    dispatchValue({ ...data });
                }
                setLoading(false);
            }),
        );
    };
    //**Function to Fetch Booking details from API and plot in initial Screeen */
    useEffect(() => {
        fetchVendorBookingDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //**Function to render heading */
    //**@props label */
    const renderHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '200px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderPackageHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '295px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    //**Function to render values */
    //**@props value */
    const renderValue = (value: string | number) => {
        return (
            <Typography variant='h4' component={'h4'} sx={styles.value_text}>
                {value}
            </Typography>
        );
    };

    const renderPackageDetails = () => {
        if (listData && listData?.bookingDetails?.package) {
            const packagePostFixArray = listData?.bookingDetails?.package?.packageHours.split(':');
            const packagePostFix = parseInt(packagePostFixArray[0]) > 0 ? 'Hrs' : 'Minutes';
            const durationPostFixArray = listData?.bookingDetails?.package?.videoLength.split(':');
            const durationPostFix =
                parseInt(durationPostFixArray[0]) > 0
                    ? 'Hrs'
                    : parseInt(durationPostFixArray[1]) > 0
                    ? 'Minutes'
                    : 'Secs';
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Package Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer sx={{ height: '340px' }}>
                            <LineContainer>
                                {renderPackageHeading('Name')}
                                {renderValue(`${listData?.bookingDetails?.package?.name || 'N/A'}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Description')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.description || 'N/A'}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Price')}
                                {renderValue(`$${listData?.bookingDetails?.package?.price || 0}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Package Hours')}
                                {renderValue(
                                    `${
                                        listData?.bookingDetails?.package?.packageHours || 0
                                    } ${packagePostFix}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Edited Photos Count')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.editedPhotosCount || 0}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Edited Videos Count')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.editedVideosCount || 0}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Video length')}
                                {renderValue(
                                    `${
                                        listData?.bookingDetails?.package?.videoLength || 0
                                    } ${durationPostFix}`,
                                )}
                            </LineContainer>
                            <LineContainer sx={{ alignItems: 'flex-start' }}>
                                {renderPackageHeading('With Assistant Photographer/Videographer')}
                                {renderValue(
                                    `${
                                        listData?.bookingDetails?.package?.isAssistant
                                            ? 'Yes'
                                            : 'No'
                                    }`,
                                )}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderLocation = (val: any) => {
        if (val?.street || val?.city || val?.postalCode || val?.country) {
            return (
                <Typography
                    variant='h4'
                    component={'h4'}
                    sx={{ ...styles.value_text, maxWidth: '450px' }}>
                    {`${val?.street}${val?.street && val?.city && ','}  ${val?.city} ${
                        val?.country && val?.city && ','
                    } ${val?.country}${val?.postalCode && val?.country && ','}  ${val?.postalCode}`}
                </Typography>
            );
        }
        return (
            <Typography
                variant='h4'
                component={'h4'}
                sx={{ ...styles.value_text, maxWidth: '450px' }}>
                {'N/A'}
            </Typography>
        );
    };

    //**Function to render AddOns Part */
    //**@props data */
    const renderAddons = (data: any) => {
        if (data && data.length > 0) {
            return (
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {data.map((item: any, index: number) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '20px',
                                        justifyContent: 'flex-start',
                                        marginBottom: index !== data.length - 1 ? '5px' : 'unset',
                                    }}
                                    key={item?.name}>
                                    <Typography
                                        variant='h4'
                                        component={'h4'}
                                        sx={[styles.addons_para, { minWidth: '45px' }]}>
                                        {`${'$'}${item?.rate}`}
                                    </Typography>
                                    <Typography
                                        variant='h4'
                                        component={'h4'}
                                        sx={styles.addons_para}>
                                        {item?.name}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </React.Fragment>
            );
        }
        return renderValue('N/A');
    };
    //**Function to render Client image and Name */
    //**@props data */
    const renderClientView = (data: any) => {
        const user_img = data?.clientDetails?.profilePic;
        const user_name = data?.clientDetails?.fullName;
        const user_id = data?.clientDetails?.id;
        return (
            <Box
                sx={styles.vendor_view}
                onClick={() => {
                    navigate(`${ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_DETAIL}?id=${user_id}`);
                }}>
                <img style={styles.img} src={user_img || USER_IMG} alt='vendor-img'></img>
                {renderHeading(user_name)}
            </Box>
        );
    };
    //**Function to render booking status Part */
    //**@props status */
    const renderStatusTag = (status: any) => {
        return (
            <Box
                sx={{
                    background: BOOKING_STATUS_CSS[status],
                    border: BOOKING_STATUS_BORDER[status],
                    ...styles.status_tag,
                }}>
                <Typography
                    variant='h3'
                    component={'h3'}
                    sx={{
                        fontWeight: 500,
                        fontSize: '18px',
                        color: BOOKING_STATUS_FONT[status],
                    }}>
                    {BOOKING_STATUS[status]}
                </Typography>
            </Box>
        );
    };
    //**Function to render calender image before dates */
    const renderCalenderImg = () => {
        return (
            <Box sx={{ height: '20px', width: '20px', marginRight: '10px' }}>
                <img
                    src={CALENDER_IMG}
                    alt='calender-img'
                    style={{ height: '18px', width: '18px' }}></img>
            </Box>
        );
    };
    //**Function to render profile Pic */
    //**@props Value */
    //**Will render on conditional basis */
    const renderProfilePic = (value: any) => {
        let format = value?.profilePic?.substring(
                value?.profilePic?.lastIndexOf('.') + 1,
                value?.profilePic?.length,
            ),
            imgSrc = USER_IMG;
        if (format === 'jpg' || format === 'png' || format === 'jpeg') {
            imgSrc = value?.profilePic;
        }
        return (
            <Box sx={styles.icon} id={'user-img'}>
                <img src={imgSrc} style={styles.img} alt='User-img' />
            </Box>
        );
    };
    //**Function to render payment details Part */
    //**@props data from API response */
    const renderPaymentDetails = () => {
        if (listData && listData?.paymentDetails) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Payment Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer>
                            <LineContainer>
                                {renderHeading('Paid')}
                                {renderValue(`$${listData?.paymentDetails.paid || '0'}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Paid on')}
                                {renderValue(
                                    moment(listData.paymentDetails.paidOn).format(
                                        'MM/DD/YYYY, HH:MM A',
                                    ),
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Payment Method')}
                                {renderValue(listData?.paymentDetails.paymentMethod[0])}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    //**Function to render Tip payment details Part */
    //**@props data from API Response */
    const renderTipPaymentDetails = () => {
        if (listData && listData?.transactionData?.orderCalculations?.tips) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Tip Payment Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer>
                            <LineContainer>
                                {renderHeading('Paid')}
                                {renderValue(
                                    `$${listData?.transactionData?.orderCalculations?.tips || '0'}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Paid on')}
                                {renderValue(
                                    moment(listData.paymentDetails.paidOn).format(
                                        'MM/DD/YYYY, HH:MM A',
                                    ),
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Payment Method')}
                                {renderValue(listData?.paymentDetails.paymentMethod[0])}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderCancellationDetails = () => {
        if (listData && listData?.transactionData?.paymentDetails?.canceled_at) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Cancellation Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer>
                            <LineContainer>
                                {renderHeading('Canceled by')}
                                {renderValue(listData?.transactionData?.paymentDetails?.customer)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Cancellation Date')}
                                {renderValue(
                                    moment(
                                        listData?.transactionData?.paymentDetails?.canceled_at,
                                    ).format('MM/DD/YYYY, HH:MM A'),
                                )}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    //**Function to render Ratings and Reviews Part */
    //**@props data from API response */
    const renderRatingReviews = () => {
        if (listData && listData?.ratingReviews?.length > 0) {
            return (
                <React.Fragment>
                    <Typography variant='h3'>{'Rating & Reviews'}</Typography>
                    <br></br>
                    {listData &&
                        listData?.ratingReviews?.map((item: any) => {
                            const topBarStyle = !item.isAdminRating
                                ? styles.topBar
                                : styles.topBar_admin;
                            return (
                                <TableContainer>
                                    <ItemContainer sx={{ padding: '10px 20px 10px 20px' }}>
                                        <Box sx={topBarStyle}>
                                            {!item.isAdminRating &&
                                                renderProfilePic(item?.clientDetails?.profilePic)}
                                            <Box sx={styles.userInfo}>
                                                <Typography variant='h5'>
                                                    {!item.isAdminRating
                                                        ? item?.clientDetails?.fullName
                                                        : item?.adminDetails?.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '10px',
                                                        alignItems: 'center',
                                                    }}>
                                                    <Typography color={'black'} fontSize={'20px'}>
                                                        {item?.rating
                                                            ? `${item.rating}${'.0'}`
                                                            : '0.0'}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            '& > legend': { mt: 2 },
                                                        }}>
                                                        <Rating
                                                            name='simple-controlled'
                                                            value={item.rating}
                                                        />
                                                    </Box>
                                                    <Typography
                                                        variant='inherit'
                                                        color={'gray'}
                                                        sx={{ marginLeft: '20px' }}>
                                                        {item?.updatedAt
                                                            ? moment(item.updatedAt).format(
                                                                  'MM/DD/YYYY, hh:mm A',
                                                              )
                                                            : 'N/A'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={styles.menu}>
                                                <LongMenu
                                                    ratingId={item?._id}
                                                    isAdminRating
                                                    isHide={item?.isHide}
                                                    onRemoveSuccess={fetchVendorBookingDetails}
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '15px', color: 'black' }}>
                                                {item?.review}
                                            </Typography>
                                        </Box>
                                    </ItemContainer>
                                </TableContainer>
                            );
                        })}
                </React.Fragment>
            );
        }
        return null;
    };
    //**Function to render Packages Part */
    const renderPackages = () => {
        const bookingLocation = listData?.bookingDetails?.shootLocation;
        return (
            <React.Fragment>
                <TableContainer sx={{ display: 'block', marginBottom: '10px' }}>
                    <ItemContainer>
                        <LineContainer>
                            {renderHeading('Client')}
                            {renderClientView(listData)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Location')}
                            {renderLocation(bookingLocation)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Date  and Time')}
                            {renderCalenderImg()}
                            {renderValue(
                                listData?.startTime
                                    ? `${moment(listData?.startTime).format(
                                          'MM/DD/YYYY, hh:mm A',
                                      )} ${' - '} ${moment(listData?.endTime).format('hh:mm A')}`
                                    : 'N/A',
                            )}
                        </LineContainer>
                        <LineContainer sx={{ alignItems: 'flex-start' }}>
                            {renderHeading('Add ons')}
                            {renderAddons(listData?.bookingDetails?.addOnServices)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Total')}
                            {renderValue(
                                `${'$'}${
                                    listData?.transactionData?.orderCalculations?.totalOrderValue ||
                                    '0'
                                }`,
                            )}
                        </LineContainer>
                        {renderStatusTag(listData?.status)}
                    </ItemContainer>
                </TableContainer>
                {renderPackageDetails()}
                {renderPaymentDetails()}
                {renderTipPaymentDetails()}
                {renderCancellationDetails()}
                {renderRatingReviews()}
            </React.Fragment>
        );
    };
    //**Function to render Overall UI on conditional basis of data in State*/
    const renderView = () => {
        if (!listData && loading) {
            return <PackagesSkeleton />;
        }
        return renderPackages();
    };

    return (
        <Box sx={styles.paper}>
            <Box sx={styles.container}>{renderView()}</Box>
        </Box>
    );
};

export default PhotographerBookingDetail;
