import { lazy } from 'react';

//components imports
import ROUTE_NAMES from './routeNames';
import PrivateRoute from './privateRoute';
import MainLayout from 'layout/mainLayout';
import RoutesContainer from 'components/routesContainer';
import MyProfile from 'modules/profile';
import ClientDetail from 'modules/clientManagement/clientDetail';
import PhotographerDetail from 'modules/photographersManagement/photographerDetail';
import AddNotifications from 'modules/notificationsManagement/addNotifications/index';
import EditNotifications from 'modules/notificationsManagement/editNotifications';
import ReportsAndAnalytics from 'modules/reportAndAnalytics';
import RatingAndReviewDetails from 'modules/ratingReviewManagement/details';
import PhotographerBookingDetail from 'modules/photographersManagement/tabs/booking/bookingDetail';
import ClientBookingDetail from 'modules/clientManagement/tabs/booking/bookingDetail';
import EarningAndTransactionManagement from 'modules/earningTransactionManagement';
import EarningsTransactionsDetail from 'modules/earningTransactionManagement/earningsTransactionsDetail';
import CustomerSatisfaction from 'modules/customerSatisfaction';
import MangeDisputeDetail from 'modules/customerSatisfaction/details/reportedAccount';
import ChatBox from 'modules/customerSatisfaction/chatModule';
import FlaggedAccountDetail from 'modules/customerSatisfaction/details/flaggedAccount';
import TechincalIssueDetail from 'modules/customerSatisfaction/details/technicalIssues';
import ContentManagement from 'modules/contentManagement';

//pages import
const Dashboard = RoutesContainer(lazy(() => import('modules/dashboard')));
const ClientManagement = RoutesContainer(lazy(() => import('modules/clientManagement')));
const PhotographersManagement = RoutesContainer(
    lazy(() => import('modules/photographersManagement')),
);
const CategoryManagement = RoutesContainer(lazy(() => import('modules/categoryManagement')));
const NotificationsManagement = RoutesContainer(
    lazy(() => import('modules/notificationsManagement')),
);
const RatingAndReviewManagement = RoutesContainer(
    lazy(() => import('modules/ratingReviewManagement')),
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: ROUTE_NAMES.BASE_ROUTE,
    element: <MainLayout />,
    children: [
        {
            path: ROUTE_NAMES.MAIN_ROUTES.DASHBOARD,
            element: (
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT,
            element: (
                <PrivateRoute>
                    <ClientManagement />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_DETAIL,
            element: (
                <PrivateRoute>
                    <ClientDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_BOOKING_DETAIL,
            element: (
                <PrivateRoute>
                    <ClientBookingDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT,
            element: (
                <PrivateRoute>
                    <PhotographersManagement />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL,
            element: (
                <PrivateRoute>
                    <PhotographerDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_BOOKING_DETAIL,
            element: (
                <PrivateRoute>
                    <PhotographerBookingDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CATEGORY_MANAGEMENT,
            element: (
                <PrivateRoute>
                    <CategoryManagement />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.EARNINGS_TRANSACTIONS_MANAGEMENT,
            element: (
                <PrivateRoute>
                    <EarningAndTransactionManagement />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.EARNINGS_TRANSACTIONS_MANAGEMENT_DETAIL,
            element: (
                <PrivateRoute>
                    <EarningsTransactionsDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.ACCOUNT_SETTINGS,
            element: (
                <PrivateRoute>
                    <MyProfile />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT,
            element: (
                <PrivateRoute>
                    <NotificationsManagement />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION,
            element: (
                <PrivateRoute>
                    <CustomerSatisfaction />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION_DETAIL,
            element: (
                <PrivateRoute>
                    <MangeDisputeDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.FLAGGED_ACCOUNT_DETAIL,
            element: (
                <PrivateRoute>
                    <FlaggedAccountDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.TECHNICAL_ISSUE_DETAIL,
            element: (
                <PrivateRoute>
                    <TechincalIssueDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.FEEDBACK_DETAIL,
            element: (
                <PrivateRoute>
                    <TechincalIssueDetail />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION_CHAT,
            element: (
                <PrivateRoute>
                    <ChatBox />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.FLAGGED_ACCOUNT_CHAT,
            element: (
                <PrivateRoute>
                    <ChatBox />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.ADD_NOTIFICATIONS,
            element: (
                <PrivateRoute>
                    <AddNotifications />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.VIEW_NOTIFICATIONS,
            element: (
                <PrivateRoute>
                    <EditNotifications />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.REPORTS_AND_ANALYTICS,
            element: (
                <PrivateRoute>
                    <ReportsAndAnalytics />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.RATING_AND_REVIEW_MANAGEMENT,
            element: (
                <PrivateRoute>
                    <RatingAndReviewManagement />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.RATING_AND_REVIEW_MANAGEMENT_DETAIL,
            element: (
                <PrivateRoute>
                    <RatingAndReviewDetails />
                </PrivateRoute>
            ),
        },
        {
            path: ROUTE_NAMES.MAIN_ROUTES.CONTENT_MANAGEMENT,
            element: (
                <PrivateRoute>
                    <ContentManagement />
                </PrivateRoute>
            ),
        },
    ],
};

export default MainRoutes;
